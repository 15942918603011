import filesApi from "@/api/filesApi";
import goodsPrictureApi from "@/api/goodsPictureApi";
import { Toast } from 'vant';
import { reactive, ref } from "vue";

const videoUpload = (refresh) => {
    let videoFile = ref(null);
    const videoOptions = reactive({
        video: {
            id: "",
            pictureSmall: "",
            pictureMiddle: "",
            pictureBig: "",
            productId: '',
            type: 'rotation',
            isVideo: 1
        }
    })
    const upload = (productId) => {
        videoOptions.video.productId = productId;
        let oBtn = videoFile.value;
        oBtn.click();
    }
    const dealfilechange = (e) => {
        Toast.loading({
            duration: 0,
            forbidClick: true,
            message: '上传中......',
        });
        const input = e.target;
        let files = input.files;
        console.log(files);
        getVideoTime(files[0]);
    }
    const getVideoTime = (file) => {
        window.URL = window.URL || window.webkitURL
        const url = window.URL.createObjectURL(file)
        const audioElement = new Audio(url)
        audioElement.addEventListener('loadedmetadata', () => {
            const duration = audioElement.duration
            console.log('视频时长：' + duration + '秒')
            if (duration > 22) {
                Toast.clear();
                Toast.fail('请控制录制时间在22秒之内')
                return
            }
            const size = file.size / 1024 / 1000
            if (size > 100) {
                Toast.clear();
                Toast.fail('视频文件过大，请控制在22秒之内')
                return
            }
            filesApi.uploadVideo({ type: "goods", upfile: file }).then(res => {
                if (res.status == 200) {
                    videoOptions.video.pictureMiddle = res.result.url;
                    videoOptions.video.pictureBig = res.result.video;
                    goodsPrictureApi.add(videoOptions.video).then(res1 => {
                        if (res1.status != 200) {
                            Toast.fail(res1.message);
                        }
                        refresh();
                        Toast.clear();
                    });
                } else {
                    Toast.clear();
                    Toast.fail(res.message);
                }
            })
        })


    }
    return {
        videoFile,
        upload,
        videoOptions,
        dealfilechange
    }
}

export default videoUpload;
