import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.live.offerUrl;
const liveApi = {
    offer: (params,auction) => {
        return ajaxHandle(baseurl, 'post', auction, params)
    }, 
}

export default liveApi