<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>拍品完成</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="kbox"></div>
  <div class="paysuccess">
    <div class="pay1">
      <div class="pay1_L">
        <img src="images/pj.png" />
      </div>
      <div class="pay1_R">
        <p class="p1">拍品添加完成啦！</p>
      </div>
    </div>
    <div class="pay2" style="width: 100%; margin-top: 2rem; margin-left: 5rem">
      <router-link to="/goodsList" class="seedd">查看拍品</router-link>
      <router-link to="/goodsEdit/0" class="seedd">继续添加</router-link>
      <a
        href="javascript:;"
        style="background-color: #ee0a24; color: #ebedf0"
        class="seedd"
        @click="openAuction(options.goodsId,false)"
        >一键拍卖</a
      >
    </div>
  </div>
  <div class="kbox"></div>
  <div class="w100">
    <img src="images/pay2.png" />
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <van-popup
    v-if="okOptions.isFinishAuctionShow"
    v-model:show="okOptions.isFinishAuctionShow"
    position="bottom"
    :style="{ height: '100vh' }"
  >
    <div id="size" style="position: fixed;top: 0;">
      <div class="size1" >
        <div class="size1_3" @click="okOptions.isFinishAuctionShow = false">
          <img src="images/close.png" />
        </div>
      </div>
    </div>
    <van-divider>一键拍卖</van-divider>
    <div class="addressDiv">
      <div class="addiv1">
        <van-radio-group
          v-model="okOptions.auctionParams.type"
          direction="horizontal"
        >
          <van-radio name="DEFAULT">普通拍卖</van-radio>
          <van-radio name="FIXED">一口价</van-radio>
        </van-radio-group>
      </div>
      <div class="addiv1" @click="okOptions.isFinishStartPriceShow = true">
        <div class="addiv1_l">
          {{ okOptions.auctionParams.type == "DEFAULT" ? "起拍价" : "售价" }}:
        </div>
        <div class="addiv1_r">
          <input
            v-model="okOptions.auctionParams.startPrice"
            type="text"
            readonly
            placeholder="请输入金额"
          />
        </div>
      </div>
      <div
        class="addiv1"
        @click="okOptions.isFinishBidPriceShow = true"
        v-if="okOptions.auctionParams.type == 'DEFAULT'"
      >
        <div class="addiv1_l">加价金额:</div>
        <div class="addiv1_r">
          <input
            v-model="okOptions.auctionParams.bidAmount"
            type="text"
            readonly
            placeholder="请输加价金额"
          />
        </div>
      </div>
      <div class="addiv1" @click="okOptions.showFinishPicker = true">
        <div class="addiv1_l">开始时间:</div>
        <div class="addiv1_r">
          {{
            stringUtils.dateFormat(
              okOptions.auctionParams.startTime,
              "YYYY-MM-DD HH:mm"
            )
          }}
        </div>
      </div>
      <div class="addiv1" @click="okOptions.showFinishEndPicker = true">
        <div class="addiv1_l">结束时间:</div>
        <div class="addiv1_r">
          {{
            stringUtils.dateFormat(
              okOptions.auctionParams.endTime,
              "YYYY-MM-DD HH:mm"
            )
          }}
        </div>
      </div>
      <div class="addiv1">
        <div class="addiv1_l">是否付邮费:</div>
        <div class="addiv1_r" style="padding-left: 1rem;line-height:1rem;">
          <van-switch
            :model-value="okOptions.isMail"
            size="1.1rem"
            @update:model-value="onUpdateMail"
          />
          <div v-if="okOptions.isMail" @click="okOptions.isMailShow=true" style="float:right;border: 1px solid #333;width: 6rem;" >
            <span v-if="okOptions.auctionParams.mailPrice==''">请输入邮费金额</span>
            <span v-else>{{okOptions.auctionParams.mailPrice}}</span>
          </div>
        </div>
      </div>
      <div class="addiv1" v-if="okOptions.auctionParams.type == 'DEFAULT'">
        <div class="addiv1_l">设置保证金:</div>
        <div class="addiv1_r" style="padding-left: 1rem;line-height:1rem;">
          <van-switch
            :model-value="okOptions.isBond"
            size="1.1rem"
            @update:model-value="onUpdateBond"
          />
          <div  v-if="okOptions.isBond&&okOptions.auctionParams.type == 'DEFAULT'" @click="okOptions.isBondShow=true"   style="float:right;border: 1px solid #333;width: 6rem;" >
            <span v-if="okOptions.auctionParams.bondPrice==''">请输入保证金金额</span>
            <span v-else>{{okOptions.auctionParams.bondPrice}}</span>
          </div>
        </div>
      </div>
      <div
        class="addiv1"
        style="height: 10vh"
        v-if="okOptions.auctionParams.type == 'DEFAULT'"
      >
        <div class="addiv1_l">延时设置:</div>
        <div class="addiv1_r">
          <div class="sx_2">
            <a
              :class="okOptions.auctionParams.delayedTime == 0 ? 'onCurr' : ''"
              href="javascript:;"
              @click="okOptions.auctionParams.delayedTime = 0"
              >不设置</a
            >
            <a
              :class="okOptions.auctionParams.delayedTime == 30 ? 'onCurr' : ''"
              @click="okOptions.auctionParams.delayedTime = 30"
              href="javascript:;"
              >30秒</a
            >
            <a
              :class="okOptions.auctionParams.delayedTime == 60 ? 'onCurr' : ''"
              @click="okOptions.auctionParams.delayedTime = 60"
              href="javascript:;"
              >1分钟</a
            >
            <a
              :class="
                okOptions.auctionParams.delayedTime == 180 ? 'onCurr' : ''
              "
              @click="okOptions.auctionParams.delayedTime = 180"
              href="javascript:;"
              >3分钟</a
            >
            <a
              :class="
                okOptions.auctionParams.delayedTime == 300 ? 'onCurr' : ''
              "
              @click="okOptions.auctionParams.delayedTime = 300"
              href="javascript:;"
              >5分钟</a
            >
            <a
              :class="
                okOptions.auctionParams.delayedTime == 600 ? 'onCurr' : ''
              "
              @click="okOptions.auctionParams.delayedTime = 600"
              href="javascript:;"
              >10分钟</a
            >
          </div>
        </div>
      </div>
      <div class="addiv1" style="height: 30vh">
        <div>
          <div
            class="redpacket"
            style="padding-top: 0.3rem; width: 100%; padding-bottom: 1rem"
          >
            <p
              style="text-indent: 0.4rem;margin-left: 1rem; margin-right: 0.5rem;font-size: 0.8rem;line-height: 1.5rem;"
            >
              拍卖成功后您愿意拿出拍品成交额的一定比例用来给参拍人员发红包吗？
            </p>
          </div>
          <div class="clear"></div>
          <div class="redpacket_change" >
            <a
              :class="okOptions.auctionParams.amountRatio == 0 ? 'onCurr' : ''"
              href="javascript:;"
              @click="setRedPacket(false, 0)"
              >不发红包</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 1 ? 'onCurr' : ''"
              @click="setRedPacket(true, 1)"
              href="javascript:;"
              >1%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 2 ? 'onCurr' : ''"
              @click="setRedPacket(true, 2)"
              href="javascript:;"
              >2%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 3 ? 'onCurr' : ''"
              @click="setRedPacket(true, 3)"
              href="javascript:;"
              >3%</a
            >
             <a
              :class="okOptions.auctionParams.amountRatio == 4 ? 'onCurr' : ''"
              @click="setRedPacket(true, 4)"
              href="javascript:;"
              >4%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 5 ? 'onCurr' : ''"
              @click="setRedPacket(true, 5)"
              href="javascript:;"
              >5%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 6 ? 'onCurr' : ''"
              @click="setRedPacket(true, 6)"
              href="javascript:;"
              >6%</a
            >
             <a
              :class="okOptions.auctionParams.amountRatio == 7 ? 'onCurr' : ''"
              @click="setRedPacket(true, 7)"
              href="javascript:;"
              >7%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 8 ? 'onCurr' : ''"
              @click="setRedPacket(true, 8)"
              href="javascript:;"
              >8%</a
            >
             <a
              :class="okOptions.auctionParams.amountRatio == 9 ? 'onCurr' : ''"
              @click="setRedPacket(true, 9)"
              href="javascript:;"
              >9%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 10 ? 'onCurr' : ''"
              @click="setRedPacket(true, 10)"
              href="javascript:;"
              >10%</a
            >
          </div>
        </div>
      </div>
      <div class="size3" style="position: fixed;">
        <a href="javascript:;" @click="onekey">发布拍卖</a>
      </div>
    </div>
  </van-popup>
  <van-popup v-model:show="okOptions.isMailShow" :overlay="false" position="bottom">
  <van-number-keyboard
    v-if="okOptions.isMailShow"
    v-model="okOptions.auctionParams.mailPrice"
    theme="custom"
    extra-key="."
    :hide-on-click-outside="true"
    close-button-text="完成"
    :show="okOptions.isMailShow"
    @blur="okOptions.isMailShow = false"
  />
  </van-popup>
  <van-popup v-model:show="okOptions.isFinishStartPriceShow" :overlay="false" position="bottom">
  <van-number-keyboard
    v-if="okOptions.isFinishStartPriceShow"
    v-model="okOptions.auctionParams.startPrice"
    theme="custom"
    extra-key="."
    :hide-on-click-outside="true"
    close-button-text="完成"
    :show="okOptions.isFinishStartPriceShow"
    @blur="okOptions.isFinishStartPriceShow = false"
  />
  <van-popup v-model:show="okOptions.isBondShow" :overlay="false" position="bottom">
    <van-number-keyboard
      v-if="okOptions.isBondShow"
      v-model="okOptions.auctionParams.bondPrice"
      theme="custom"
      extra-key="."
      :hide-on-click-outside="true"
      close-button-text="完成"
      :show="okOptions.isBondShow"
      @blur="okOptions.isBondShow = false"
    />
  </van-popup>
  </van-popup>
  <van-popup v-model:show="okOptions.isFinishBidPriceShow" :overlay="false" position="bottom">
  <van-number-keyboard
    v-if="okOptions.isFinishBidPriceShow"
    v-model="okOptions.auctionParams.bidAmount"
    theme="custom"
    extra-key="."
    :hide-on-click-outside="true"
    close-button-text="完成"
    :show="okOptions.isFinishBidPriceShow"
    @blur="okOptions.isFinishBidPriceShow = false"
  />
  </van-popup>
  <van-popup v-model:show="okOptions.showFinishPicker" position="bottom">
    <van-datetime-picker
      type="datetime"
      :minDate="okOptions.minDate"
      @confirm="onStartConfirm"
      @cancel="okOptions.showFinishPicker = false"
    />
  </van-popup>
  <van-popup v-model:show="okOptions.showFinishEndPicker" position="bottom">
    <van-datetime-picker
      type="datetime"
      :minDate="okOptions.auctionParams.startTime"
      @confirm="onEndConfirm"
      @cancel="okOptions.showFinishEndPicker = false"
    />
  </van-popup>
  <van-popup v-model:show="okOptions.isBondShow" :overlay="false" position="bottom">
    <van-number-keyboard
      v-if="okOptions.isBondShow"
      v-model="okOptions.auctionParams.bondPrice"
      theme="custom"
      extra-key="."
      :hide-on-click-outside="true"
      close-button-text="完成"
      :show="okOptions.isBondShow"
      @blur="okOptions.isBondShow = false"
    />
  </van-popup>
</template>

<script>
import { useStore } from "vuex";
import { reactive, computed } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import goodsApi from "@/api/goodsApi";
import oneKeyModel from "@/model/auction/onekeyModel";
import { Toast, Dialog } from "vant";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const options = reactive({
      goodsId: route.params.id,
      goods: {},
      item: {
        title: "",
      },
      screenFinishShow: false,
      isFinishTypeShow: false,
      isFinishStatusShow: false,
      delayeds: [],
    });
    const {
      okOptions,
      openAuction,
      onekey,
      onStartConfirm,
      onDelayedFinish,
      onEndConfirm,
      setRedPacket,
      onUpdateMail,
      onUpdateBond,
    } = oneKeyModel(Toast, Dialog);
    const store = useStore();
    options.delayeds = computed(() => store.state.common.delayeds);
    async function submit() {
      await goodsApi.submit({ id: options.goodsId }).then((res) => {
        if (res.status == 200) {
          router.push("goodsList");
        } else {
          Toast.fail(res.message);
        }
      });
    }
    return {
      options,
      okOptions,
      submit,
      onekey,
      openAuction,
      onStartConfirm,
      onDelayedFinish,
      onEndConfirm,
      setRedPacket,
      onUpdateMail,
      onUpdateBond,
    };
  },
};
</script>

<style>
#size {
  height: 0.3rem;
}
.van-radio-group {
  margin-left: 6rem;
}
.custom-button {
  width: 26px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #ee0a24;
  border-radius: 100px;
}
.redpacket p {
  float: left;
}
.paysuccess .pay2 a.seedd {
  width: 4rem !important;
  margin-left: 0.5rem;
}
.redpacket_change {
  width: 95%;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: .5rem;
}
.redpacket_change a{
    padding: 0.3rem 0.8rem;
    float: left;
    font-size: 0.75rem;
    white-space: nowrap;
    background: #eee;
    color: #666;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    line-height: 1rem;
}
.onCurr {
  color: #fff !important;
  background: #ff2150 !important;
}
</style>