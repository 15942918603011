import auctionApi from "@/api/auctionApi";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";


const oneKeyModel = (Toast, Dialog) => {
  const router = useRouter();
  let curTime = new Date();
  curTime = new Date(curTime.setHours(curTime.getHours() + 1));
  const okOptions = reactive({
    auctionParams: {
      type: "DEFAULT",
      goodsId: "",
      delayedTime: 0,
      delayedText: "不设置",
      startTime: new Date(),
      endTime: curTime,
      startPrice: "",
      bidAmount: "",
      isRedPacket: false,
      amountRatio: 0,
      isMail:0,
      isBond:0,
      mailPrice:'',
      bondPrice:''
    },
    minDate: curTime,
    isFinishAuctionShow: false,
    showFinishPicker: false,
    showFinishDelayed: false,
    showFinishEndPicker: false,
    isFinishStartPriceShow: false,
    isFinishBidPriceShow: false,
    isMail:false,
    isMailShow:false,
    isBond:false,
    isBondShow:false,
  })

  const setRedPacket = (isOk, value) => {
    okOptions.auctionParams.amountRatio = value;
    okOptions.auctionParams.isRedPacket = isOk;
  }
  const onUpdateMail=(val)=>{
    okOptions.isMail=val;
    okOptions.auctionParams.isMail=okOptions.isMail?1:0;
  }
  const onUpdateBond=(val)=>{
    okOptions.isBond=val;
    okOptions.auctionParams.isBond=okOptions.isBond?1:0;
  }
  const openAuction = (goodsId,isRedPacket) => {
    console.log(goodsId);
    okOptions.auctionParams = {
      type: "DEFAULT",
      goodsId: goodsId,
      delayedTime: 0,
      delayedText: "不设置",
      startPrice: "",
      bidAmount: "",
      endTime: curTime,
      isRedPacket: isRedPacket,
      amountRatio: 0,
      isMail: 0,
      mailPrice: '',
      bondPrice:''
    };
    var curTime = new Date();
    okOptions.auctionParams.startTime = new Date(
      curTime.setHours(curTime.getHours() + 1)
    );
    okOptions.auctionParams.endTime = new Date(
      curTime.setHours(curTime.getHours() + 1)
    );
    //options.minDate=options.auctionParams.startTime;
    okOptions.isFinishAuctionShow = true;
  }

  function onStartConfirm(value) {
    okOptions.auctionParams.startTime = value;
    okOptions.showFinishPicker = false;
  }
  function onDelayedFinish({ selectedOptions, value }) {
    okOptions.auctionParams.delayedText = selectedOptions
      .map((option) => option.text)
      .join();
    okOptions.auctionParams.delayedTime = value;
    okOptions.showFinishDelayed = false;
  }
  function onEndConfirm(value) {
    okOptions.auctionParams.endTime = value;
    okOptions.showFinishEndPicker = false;
  }

  const onekey = () => {
    if (okOptions.auctionParams.startTime >= okOptions.auctionParams.endTime ||
      okOptions.auctionParams.endTime == ""
    ) {
      Toast.fail("开始时间不能小于等于结束时间或拍卖时间不能为空");
      return;
    }
    if (okOptions.auctionParams.startPrice == "") {
      Toast.fail("起拍价不能为空！");
      return;
    }
    if(okOptions.isBond){
      if (okOptions.auctionParams.bondPrice == ""||parseFloat(okOptions.auctionParams.bondPrice) == 0) {
        Toast.fail("请添加保证金金额！");
        return;
      }
    }
    if(okOptions.isMail){
      if (okOptions.auctionParams.mailPrice == ""||parseFloat(okOptions.auctionParams.mailPrice) == 0) {
        Toast.fail("请添加邮费金额或零！");
        return;
      }
    }
    if (
      (okOptions.auctionParams.bidAmount == "" ||
      parseFloat(okOptions.auctionParams.bidAmount) == 0) &&
      okOptions.auctionParams.type == "DEFAULT"
    ) {
      Toast.fail("加价金额不能为空或零！");
      return;
    }
    if (
      (okOptions.auctionParams.startPrice == "" ||
      parseFloat(okOptions.auctionParams.startPrice) == 0) &&
      okOptions.auctionParams.type == "FIXED"
    ) {
      Toast.fail("一口价拍卖价格不能为空或零！");
      return;
    }
    okOptions.auctionParams.endTime = moment(okOptions.auctionParams.endTime);
    Dialog.confirm({
      title: "一键拍卖",
      message: "亲，您确认要生成拍卖？",
    }).then(() => {
      auctionApi.onekey(okOptions.auctionParams).then((res) => {
        if (res.status == 200) {
          router.push({ path: "/myAuction" });
        } else {
          Toast.fail(res.message);
        }
      });
    });
  }

  return {
    okOptions,
    openAuction,
    onekey,
    onStartConfirm,
    onDelayedFinish,
    onEndConfirm,
    setRedPacket,
    onUpdateMail,
    onUpdateBond
  }
}

export default oneKeyModel