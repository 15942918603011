<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC"></div>
      <div class="headerR">
        <a href="javascript:void()"><img src="images/dph.png" /></a>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div
    class="dpxqbox1"
    :style="
      options.shop.headImg
        ? 'background: url(' +
          options.shop.headImg +
          ') no-repeat;background-size: 100%;'
        : 'background: url(/images/dpbg.png) no-repeat;background-size: 100%;'
    "
  >
    <div class="dpxqbox1L">
      <div class="dpimg">
        <img :src="options.shop.logo ? options.shop.logo : 'images/dp1.png'" />
      </div>
      <div class="dptxt">
        <p class="p1">{{ options.shop.title }}</p>
        <p class="p2">
          {{
            options.shop.collectionNum == null ? 0 : options.shop.collectionNum
          }}人收藏
        </p>
      </div>
    </div>
    <div class="dpxqbox1R"></div>
    <div class="clear"></div>
  </div>

  <div class="clear"></div>
  <div class="dpsub">
    <div class="dpsub1">
      <div class="dpsubL">
        开店时间<span>{{
          stringUtils.dateFormat(options.shop.createTime, "yyyy-MM-DD HH:mm")
        }}</span>
      </div>
    </div>
    <div class="dpsub1 borbot">
      <div class="dpsubL">
        服务电话<span>{{ options.shop.contactPhone }}</span>
      </div>
      <div class="dpsubR">
        <img src="images/tel.png" />
      </div>
    </div>
  </div>
  <div class="kbox"></div>
  <div class="likebox" style="margin-bottom: 2rem">
    <div class="likeTit">
      <span class="red">店铺拍卖</span>
    </div>
    <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="options.loading"
        :finished="options.finished"
        :offset="200"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <ul class="van-clearfix">
          <li v-for="a in options.auctions" :key="a.id">
            <a href="javascript:;">
              <van-image
                width="11rem"
                height="11rem"
                class="proimg"
                style="object-fit: cover"
                :src="a.pictureSmall"
              />
              <p class="tit">{{ a.title }}</p>
              <p class="price">
                <vab-count-down
                  :startTime="a.startTime"
                  :endTime="a.endTime"
                ></vab-count-down
                ><img src="images/del.png" />
              </p>
            </a>
          </li>
        </ul>
      </van-list>
    </van-pull-refresh>
  </div>
  <div class="kbox"></div>
  <div class="kbox"></div>
  <div class="footbox">
    <div class="footer">
      <ul >
        <li :class="ac == 'index' ? 'on' : ''">
          <router-link :to="'/shop/' + options.shop.id" class="curr">
            <img src="images/dpic0.png" />
            <p>浏览</p>
          </router-link>
        </li>
        <li :class="ac == 'snap' ? 'on' : ''">
          <a href="javascript:;" @click="options.isEditShow = true">
            <img src="images/icon3.png" />
            <p>编辑</p>
          </a>
        </li>
        <li>
          <a
            href="javascript:;"
            @click="showTypeHandle('auction')"
            class="curr message"
          >
            <img src="images/f02.png" />
            <p>推荐</p>
          </a>
        </li>
        <li :class="ac == 'my' ? 'on' : ''">
          <router-link to="/member">
            <img v-if="ac == 'my'" src="images/f4.png" />
            <img v-else src="images/f04.png" />
            <p>我的</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <van-popup
    v-model:show="options.isHeadShow"
    :style="{ height: '66vh' }"
    position="bottom"
  >
    <van-divider
      :style="{ color: '#ff2150', borderColor: '#ff2150', padding: '0 16px' }"
    >
      修改头部图片
    </van-divider>
    <van-uploader :after-read="modifyHeadImg" style="margin-bottom: 2rem">
      <van-action-bar-button color="#ff2150" type="danger" size="small"
        >上传头部图片</van-action-bar-button
      >
    </van-uploader>
    <img
      @click="options.isHeadShow = false"
      src="images/close.png"
      style="float: right; margin-top: -3rem; width: 1rem"
    />

    <div style="width: 100%">
      <p>
        <img
          :src="
            options.sumbitParams.headImg == ''
              ? '/images/pic.png'
              : options.sumbitParams.headImg
          "
        />
      </p>
    </div>
    <div class="sx_3">
      <a href="javascript:;" @click="sumbit('head')">修改</a>
    </div>
  </van-popup>
  <van-popup
    v-model:show="options.isLogoShow"
    :style="{ height: '40vh' }"
    position="bottom"
  >
    <van-divider
      :style="{ color: '#ff2150', borderColor: '#ff2150', padding: '0 16px' }"
    >
      修改Logo
    </van-divider>
    <img
      @click="options.isLogoShow = false"
      src="images/close.png"
      style="float: right; margin-top: -3rem; width: 1rem"
    />
    <van-field name="uploader" label="Logo上传">
      <template #input>
        <van-uploader
          style="float: right"
          :after-read="modifyLogo"
          multiple
          :max-count="1"
        />
        <van-image width="100" :src="options.sumbitParams.logo" />
      </template>
    </van-field>
    <div class="sx_3">
      <a href="javascript:;" @click="sumbit('logo')">修改</a>
    </div>
  </van-popup>
  <van-popup
    v-model:show="options.isTitleShow"
    :style="{ height: '30vh' }"
    position="bottom"
  >
    <van-divider
      :style="{ color: '#ff2150', borderColor: '#ff2150', padding: '0 16px' }"
    >
      修改标题
    </van-divider>
    <img
      @click="options.isTitleShow = false"
      src="images/close.png"
      style="float: right; margin-top: -3rem; width: 1rem"
    />
    <van-field
      v-model="options.sumbitParams.title"
      label="标题："
      placeholder="请输入店铺标题"
    />
    <div class="sx_3">
      <a href="javascript:;" @click="sumbit('title')">修改</a>
    </div>
  </van-popup>
  <van-popup
    v-model:show="options.isPhoneShow"
    :style="{ height: '70vh' }"
    position="bottom"
  >
    <van-divider
      :style="{ color: '#ff2150', borderColor: '#ff2150', padding: '0 16px' }"
    >
      修改服务电话
    </van-divider>
    <img
      @click="options.isPhoneShow = false"
      src="images/close.png"
      style="float: right; margin-top: -3rem; width: 1rem"
    />
    <van-field
      v-model="options.sumbitParams.contactPhone"
      @click="options.isNumberShow = true"
      label="服务电话："
      placeholder="请输入服务电话"
    />
    <div class="sx_3">
      <a href="javascript:;" @click="modifyPhone">修改</a>
    </div>
  </van-popup>
  <van-popup
    v-model:show="options.isNumberShow"
    :overlay="false"
    position="bottom"
  >
    <van-number-keyboard
      v-model="options.sumbitParams.contactPhone"
      theme="custom"
      extra-key="."
      close-button-text="完成"
      :show="options.isNumberShow"
      @blur="options.isNumberShow = false"
    />
  </van-popup>
  <van-popup
    v-model:show="options.isApplyPhoneShow"
    :overlay="false"
    position="bottom"
  >
    <van-number-keyboard
      v-model="options.shop.contactPhone"
      theme="custom"
      extra-key="."
      close-button-text="完成"
      :show="options.isApplyPhoneShow"
      @blur="options.isApplyPhoneShow = false"
    />
  </van-popup>
  <van-popup
    v-model:show="options.isApply"
    :style="{ height: '100vh' }"
    position="bottom"
  >
    <van-divider
      :style="{ color: '#ff2150', borderColor: '#ff2150', padding: '0 16px' }"
    >
      店铺申请
    </van-divider>
    <van-field
      v-model="options.shop.title"
      required
      label="店铺标题："
      placeholder="请输入店铺标题"
    />
    <van-cell
      title="服务电话："
      style="padding-left: 1.5rem"
      center
      is-link
      @click="options.isApplyPhoneShow = true"
      :value="options.shop.contactPhone"
    />

    <van-field name="uploader" required label="Logo：">
      <template #input>
        <van-uploader
          style="float: right"
          :after-read="ApplyLogo"
          multiple
          :max-count="1"
        />
        <van-image width="100" :src="options.shop.logo" />
      </template>
    </van-field>
    <van-field name="uploader" required label="头部图片：">
      <template #input>
        <van-uploader
          style="float: right"
          :after-read="ApplyHeadImg"
          multiple
          :max-count="1"
        />
        <van-image width="100" :src="options.shop.headImg" />
      </template>
    </van-field>
    <div class="sx_3">
      <a href="javascript:;" @click="applySumbit">申请</a>
    </div>
  </van-popup>
  <van-action-sheet
    v-model:show="options.isEditShow"
    :actions="options.actions"
    @select="onSelect"
  />
</template>

<script>
import { reactive } from "vue";
import filesApi from "@/api/filesApi";
import shopApi from "@/api/shopApi";
import goodsApi from "@/api/goodsApi";
import auctionApi from "@/api/auctionApi";
import VabCountDown from "@/components/VabCountDown.vue";
import { Toast, Dialog } from "vant";
export default {
  components: {
    VabCountDown,
  },
  setup() {
    const options = reactive({
      shop: {
        id: "",
        headImg: "",
        title: "",
        logo: "",
        contactPhone: "",
      },
      sumbitParams: {
        headImg: "",
        title: "",
        logo: "",
        contactPhone: "",
      },
      actions: [
        { name: "修改标题", code: "title" },
        { name: "头部图片", code: "head" },
        { name: "logo", code: "logo" },
        { name: "服务电话", code: "phone" },
      ],
      showType: "index",
      recommends: [],
      goods: [],
      auctions: [],
      pageCount: 1,
      isHeadShow: false,
      isLogoShow: false,
      isTitleShow: false,
      isPhoneShow: false,
      isRecommendShow: false,
      isNumberShow: false,
      isApplyPhoneShow: false,
      isApply: false,
      isEditShow: false,
      loading: false,
      finished: false,
      refreshing: false,
      pageNo: 0,
      total: 0,
      totalCount: {
        online: 0,
        offline: 0,
        passin: 0,
        end: 0,
        all: 0,
      },
    });
    const paramsGoods = reactive({
      draw: 1,
      start: 0,
      length: 10,
      title: "",
      typeId: "",
      typeName: "",
      status: "",
      statusName: "",
    });
    const paramsAuction = reactive({
      draw: 1,
      pageNo: 0,
      length: 10,
      online: 1,
      shop: 1,
    });
    const onSelect = (item) => {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      switch (item.code) {
        case "title":
          options.isTitleShow = true;
          break;
        case "head":
          options.isHeadShow = true;
          break;
        case "logo":
          options.isLogoShow = true;
          break;
        case "phone":
          options.isPhoneShow = true;
          break;
        case "recommend":
          showTypeHandle("auction");
          break;
      }
      options.isEditShow = false;
    };
    const modifyHeadImg = (file) => {
      console.log(file);
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "上传中......",
      });
      filesApi.uploadImg({ type: "shop", upfile: file.file }).then((res) => {
        toast.clear();
        if (res.status == 200) {
          options.sumbitParams.headImg = res.result.url;
        }
      });
    };
    const getTotalCount = () => {
      auctionApi.getAuctionCount().then((res) => {
        if (res.status == 200) {
          options.totalCount = res.result;
        }
      });
    };
    const ApplyHeadImg = (file) => {
      console.log(file);
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "上传中......",
      });
      filesApi.uploadImg({ type: "shop", upfile: file.file }).then((res) => {
        toast.clear();
        if (res.status == 200) {
          options.shop.headImg = res.result.url;
        }
      });
    };
    const myGoods = () => {
      goodsApi.searchMy(paramsGoods).then((res) => {
        if (res.status == 200) {
          let count = Math.ceil(res.recordsTotal / paramsGoods.length);
          options.goods = res.data;
          options.pageCount = count;
          options.total = res.recordsTotal;
        } else {
          Toast.fail(res.message);
        }
      });
    };
    async function onRefresh() {
      options.finished = false;
      options.loading = true;
      options.refreshing = true;
      onLoad();
    }
    const onLoad = async () => {
      console.log("refreshing:" + options.refreshing);
      if (options.refreshing) {
        options.auctions = [];
        options.pageCount = 1;
        options.pageNo = 1;
        options.refreshing = false;
      } else {
        if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
      }
      console.log("pageCount:" + options.pageCount);
      paramsAuction.pageNo = options.pageNo - 1;
      console.log(options.auctions.length + ":" + options.total);
      if (
        options.auctions.length > 0 &&
        options.total > 0 &&
        options.auctions.length >= options.total
      ) {
        options.finished = true;
        options.loading = false;
        return;
      }
      console.log(JSON.stringify(paramsAuction));
      await auctionApi.searchMy(paramsAuction).then((res) => {
        if (res.status == 200) {
          options.total = res.recordsTotal;
          let count = Math.ceil(options.total / paramsAuction.length);
          options.pageCount = count;
          res.data.forEach((item) => {
            if (options.auctions.length < res.recordsTotal) {
              options.auctions.push(item);
            }
          });
          options.loading = false;
          if (options.auctions.length == res.recordsTotal) {
            options.finished = true;
          }
        } else {
          options.loading = false;
          options.finished = true;
        }
      });
    };
    const modifyLogo = (file) => {
      console.log(file);
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "上传中......",
      });
      filesApi.uploadImg({ type: "shop", upfile: file.file }).then((res) => {
        toast.clear();
        if (res.status == 200) {
          options.sumbitParams.logo = res.result.url;
        }
      });
    };
    const ApplyLogo = (file) => {
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "上传中......",
      });
      console.log(file);
      filesApi.uploadImg({ type: "shop", upfile: file.file }).then((res) => {
        toast.clear();
        if (res.status == 200) {
          options.shop.logo = res.result.url;
        }
      });
    };
    const applySumbit = () => {
      shopApi.apply(options.shop).then((res) => {
        if (res.status == 200) {
          options.isApply = false;
          get();
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const sumbit = (type) => {
      var send = {
        shopId: options.shop.id,
        content: "",
      };
      var tab1 = "标题";
      if (type == "title") {
        send.content = JSON.stringify({ title: options.sumbitParams.title });
      } else if (type == "head") {
        tab1 = "头部图片";
        send.content = JSON.stringify({
          headImg: options.sumbitParams.headImg,
        });
      } else if (type == "logo") {
        tab1 = "店铺logo";
        send.content = JSON.stringify({ logo: options.sumbitParams.logo });
      }
      Dialog.confirm({
        title: "完成",
        message: "亲，您确认要修改" + tab1 + "？",
      }).then(() => {
        shopApi.submit(send).then((res) => {
          if (res.status == 200) {
            options.isTitleShow = false;
            options.isLogoShow = false;
            options.isHeadShow = false;
            get();
          } else {
            Toast.fail(res.message);
          }
        });
      });
    };
    const modifyPhone = () => {
      shopApi
        .modifyPhone({
          shopId: options.shop.id,
          phone: options.sumbitParams.contactPhone,
        })
        .then((res) => {
          if (res.status == 200) {
            options.isPhoneShow = false;
            get();
          } else {
            Toast.fail(res.message);
          }
        });
    };
    const get = () => {
      shopApi.getMy().then((res) => {
        if (res.status == 200) {
          options.shop = res.result;
          getRecommend();
        } else if (res.status == 305) {
          options.isApply = true;
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const recommendAdd = (item, type) => {
      console.log(item.title);
      Dialog.confirm({
        title: "推荐",
        message: "亲，您确认要推荐【" + item.title + "】？",
      }).then(() => {
        shopApi
          .recommendAdd({
            shopId: options.shop.id,
            bodyId: item.id,
            type: type,
          })
          .then((res) => {
            if (res.status == 200) {
              getRecommend();
            }
          });
      });
    };
    const getRecommend = () => {
      shopApi.recommend({ shopId: options.shop.id }).then((res) => {
        if (res.status == 200) {
          options.recommends = res.result;
        }
      });
    };
    const delRecommend = (item) => {
      Dialog.confirm({
        title: "推荐删除",
        message: "亲，您确认要删除【" + item.bodyTitle + "】的推荐？",
      }).then(() => {
        shopApi.recommendDel({ id: item.id }).then((res) => {
          if (res.status == 200) {
            getRecommend();
          } else {
            Toast.fail(res.message);
          }
        });
      });
    };
    const showTypeHandle = (type) => {
      options.showType = type;
      // if (type == "goods") {
      //   myGoods();
      // } else if (type == "auction") {
      //   onRefresh();
      // }
    };
    get();
    getTotalCount();
    return {
      options,
      modifyHeadImg,
      modifyLogo,
      sumbit,
      get,
      modifyPhone,
      myGoods,
      onRefresh,
      delRecommend,
      showTypeHandle,
      recommendAdd,
      ApplyHeadImg,
      ApplyLogo,
      applySumbit,
      onSelect,
      onLoad,
    };
  },
};
</script>

<style>
.shop_edit {
  width: 5rem;
  z-index: 2000;
  position: fixed;
  right: 0;
  top: 10rem;
}
.shop_edit ul {
  width: 100%;
}
.shop_edit ul li {
  width: 4.5rem;
  height: 1.8rem;
  background: #cf141e;
  color: #fff;
  float: right;
  margin-top: 0.5rem;
  border-radius: 4px;
  line-height: 1.8rem;
}
.shop_edit ul li i {
  margin-top: 0.2rem;
  margin-right: 0.3rem;
  margin-left: 0.2rem;
}
.shop_edit ul li img {
  height: 1rem;
  margin-top: 0.4rem;
  display: block;
  float: left;
  padding-left: 0.4rem;
  padding-right: 0.2rem;
}
.van-number-keyboard {
  z-index: 2004 !important;
}
</style>