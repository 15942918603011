<template>
  <div class="headerbox">
  <div class="header">
    <div class="headerL">
      <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
    </div>
    <div class="headerC">
      <p>平台通知</p>
    </div>
    <div class="headerR"></div>
  </div>
</div>
<div class="clear"></div>
<div class="hbox"></div>
<div class="quannewsbox1">
  <div class="quannews_2">
    <div class="d1"><p>{{options.notice.title}}</p></div>
    <div class="d4"></div>
  </div>
</div>
<div class="clear"></div>
<div class="quannewsbox2">
  <div class="kbox"></div>
  <div class="quannews_4 notice" v-html="options.notice.content">
   
  </div>
</div>
<div class="clear"></div>
<div class="kbox"></div>
</template>

<script>
import cmsApi from "@/api/cmsApi";
import { reactive } from '@vue/reactivity';
import { useRoute } from "vue-router";
export default {
    setup(){
         const route = useRoute();
        const options=reactive({
            notice:{
                content:'<p></p>'
            },
            id:route.params.id
        })
        const get=()=>{
            cmsApi.getSystemNotice({id:options.id}).then(res=>{
                if(res.status==200){
                    options.notice=res.result;
                }
            })
        }
        get();
        return {
            options
        }
    }
}
</script>

<style>
.notice ol {
    list-style:decimal !important;
    margin-left: .5rem;
}
.notice ol li{
    font-size: 0.75rem;
    line-height: 1.2rem;
}
</style>