<template>
  <video :id="'video_' + options.auctionId" style="width: 100%"></video>
  <button @click="push">推流</button>
</template>

<script>
import { reactive } from "@vue/reactivity";
import liveApi from '@/api/liveApi';
import rtcHandle from "@/utils/rtcHandle";
export default {
  setup() {
    const options = reactive({
      auctionId: "123456",
      pushUrl:
        "artc://push.lecuiyipai.com/auction/2222221111?auth_key=1657099960-0-0-2ee742942af9565df0306a2175aed167",
      iceServer: {
        iceServers: [
          {
            url: "artc://push.lecuiyipai.com/auction/2222221111?auth_key=1657099960-0-0-2ee742942af9565df0306a2175aed167",
          },
        ],
      },
    });
    const { client } = rtcHandle();
    const push = () => {
      client.createPeer(options.pushUrl);
      client.addICEEventListener();
      client.onICEEventListener();
    //   client.onOfferEventListener(function (offer) {
    //     client.setRemote(offer.data.sdp);
    //     client.createAnswerFc();
    //   });
      client.createStream(
        {
          audio: true,
          video: true,
        },
        function (stream) {
          var thisvideo = document.getElementById("video_" + options.auctionId);
          thisvideo.srcObject = stream;
          thisvideo.muted = true;
          thisvideo.play();
          client.createOfferFc((desc)=>{
            liveApi.offer({
                mode: "live",
                version: 2,
                sdk_version:"0.0.1",
                jsep:JSON.stringify(desc),
            },options.auctionId+'?auth=1657195867-0-0-e7cd54693d32b48a861e2a72b14690b9').then(offer=>{
                client.setRemote(offer.jsep.sdp);
                client.createAnswerFc();
            })
          })
        }
      );
    };
    return {
      options,
      push,
    };
  },
};
</script>

<style>
</style>