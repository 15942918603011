<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
      </div>
      <div class="headerC">
        <p>我的拍品</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="goods_step">
    <van-steps :active="options.step">
      <van-step @click="options.step = 0">基础信息</van-step>
      <van-step @click="options.step = 1">简介</van-step>
      <van-step @click="options.step = 2">属性</van-step>
      <van-step @click="stepPrictureInit">图片</van-step>
    </van-steps>
  </div>
  <div class="clear"></div>
  <div class="addressDiv" style="height: 80vh" v-show="options.step == 0">
    <div style="
          margin-top: 1rem;
          right: 0px;
          height: 80vh;
          position: relative;
          overflow-y: scroll;
        ">
      <van-field v-model="options.goods.title" required label="名称：" placeholder="请输入商品名称" />
      <van-field v-model="options.goods.typeName" is-link required readonly label="类型：" placeholder="请选择商品类型"
        @click="options.isTypeShow = true" />
      <van-field v-model="options.goods.unitName" is-link required readonly label="单位：" placeholder="请选择商品单位"
        @click="options.isUntiShow = true" />
      <van-field v-model="options.goods.weight" label="重量：" placeholder="请输入商品重量" />
      <!-- <van-field
      v-model="options.goods.tabs"
      label="关键字："
      placeholder="请输入商品关键字"
    /> -->
      <van-field v-model="options.goods.brand" label="品牌：" placeholder="请输入商品品牌" />
      <van-field v-model="options.goods.price" readonly label="市场参考价：" type="number" placeholder="请输入市场参考价（元）"
        @click="options.isPriceShow = true" />
    </div>

    <van-action-bar v-if="
      (options.goodsId == '0' || options.goods.step < 4) &&
      options.goods.status == 0
    ">
      <van-action-bar-button color="#ff2150" type="danger" text="下一步" @click="next(1)" />
    </van-action-bar>
    <van-action-bar v-else>
      <van-action-bar-button v-if="options.goods.status != 2 && options.goods.status != 1" color="#ff2150" type="danger"
        text="保存" @click="save()" />
    </van-action-bar>
  </div>
  <div class="addressDiv" style="height: 80vh" v-show="options.step == 1">
    <div style="
          margin-top: 1rem;
          right: 0px;
          height: 80vh;
          position: relative;
          overflow-y: scroll;
        ">
      <van-field v-model="options.goods.introduction" rows="15" autosize label=" 简介" type="textarea" maxlength="2000"
        placeholder="请输入拍品简介" show-word-limit />
    </div>

    <van-action-bar v-if="
      (options.goodsId == '0' || options.goods.step < 4) &&
      options.goods.status == 0
    ">
      <van-action-bar-button color="#ff2150" type="danger" @click="options.step = 0" text="返回" />
      <van-action-bar-button color="#ff2150" type="danger" @click="next(1)" text="下一步" />
    </van-action-bar>
    <van-action-bar v-else>
      <van-action-bar-button v-if="options.goods.status != 2 && options.goods.status != 1" color="#ff2150" type="danger"
        text="保存" @click="save()" />
    </van-action-bar>
  </div>
  <div class="addressDiv" style="height: 80vh; display: none">
    <div v-html="options.describeValue" style="
          margin-top: 1rem;
          right: 0px;
          height: 80vh;
          position: relative;
          overflow-y: scroll;
        "></div>
    <van-action-bar v-if="
      (options.goodsId == '0' || options.goods.step < 4) &&
      options.goods.status == 0
    ">
      <van-action-bar-button color="#ff2150" type="danger" @click="next(-1)" text="返回" />
      <van-uploader :after-read="afterRead" style="float: right">
        <van-action-bar-button color="#ff2150" type="danger" size="small">选取图文</van-action-bar-button>
      </van-uploader>
      <van-action-bar-button color="#ff2150" type="danger" @click="next(1)" text="下一步" />
    </van-action-bar>
    <van-action-bar v-else>
      <van-uploader :after-read="afterRead" v-if="options.goods.status != 2 && options.goods.status != 1"
        style="float: right; text-align: center">
        <van-action-bar-button color="#ff2150" style="width: 5rem" type="danger" size="small">选取图文</van-action-bar-button>
      </van-uploader>
      <van-action-bar-button style="width: 50%" v-if="options.goods.status != 2 && options.goods.status != 1"
        color="#ff2150" type="danger" text="保存" @click="save()" />
    </van-action-bar>
  </div>
  <div class="addressDiv" style="height: 80vh" v-show="options.step == 2">
    <div id="sxtj" style="
          margin-top: 1.5rem;
          right: 0px;
          height: 75vh;
          position: relative;
          overflow-y: scroll;
          overflow-x: hidden;
          width: 100%;
          background-color: #fff;
        ">
      <div class="sx_2" style="width: 100%" v-for="info in options.productInfo" :key="info.id">
        <div v-if="info.isAdd == 0">
          <p class="tit">{{ info.key }}</p>
          <a href="javascript:;" @click="setInfo(info, v)" :class="v == info.selectValue ? 'onCurr' : ''"
            v-for="v in info.vList" :key="v">{{ v }}</a>
        </div>
        <div v-else class="info">
          <p class="tit">{{ info.key }}</p>
          <input v-model="info.selectValue" @change="setInfo(info, info.selectValue)" style="color: #333;"
            placeholder="请输入值" />
        </div>
      </div>
    </div>
    <van-action-bar v-if="
      (options.goodsId == '0' || options.goods.step < 4) &&
      options.goods.status == 0
    ">
      <van-action-bar-button color="#ff2150" type="danger" @click="options.step = 1" text="返回" />
      <van-action-bar-button color="#ff2150" type="danger" @click="next(1)" text="下一步" />
    </van-action-bar>
  </div>
  <div class="addressDiv" style="height: 80vh" v-show="options.step == 3">
    <div class="likebox goods_edit">
      <ul style="margin-top: 0.8rem">
        <li style="width:100%;" v-if="options.pictureList.length == 0">
          亲，您还没有添加图片。。。
        </li>
        <li v-else v-for="(p, index) in options.pictureList" :key="p.id" style="width: 33%">
          <a href="javascript:;" v-if="options.goods.status != 2 && options.goods.status != 1">
            <div class="goods_img">
              <img v-if="p.isVideo == 0 || p.isVideo == null" @click="openPreview(index)" :src="p.pictureMiddle"
                class="proimg" style="object-fit: cover" />
              <img v-else @click="payVideo(p.pictureBig)" :src="p.pictureMiddle" class="proimg"
                style="object-fit: cover" />
            </div>

            <p class="price">
              <code>
                  {{
                    p.isVideo == 1
                    ? "视频" : stringUtils.pictureGoodsFormat(p.type)

                  }} </code><img src="images/del.png" @click="delPricture(p.id)" />
            </p>
          </a>
          <a href="javascript:;" v-else>
            <div class="goods_img">
              <img v-if="p.isVideo == 0 || p.isVideo == null" @click="openPreview(index)" :src="p.pictureMiddle"
                class="proimg" style="object-fit: cover" />
              <img v-else @click="payVideo(p.pictureBig)" :src="p.pictureMiddle" class="proimg"
                style="object-fit: cover" />
            </div>
            <p class="price">
              <code style="text-align: center; margin-left: 2rem">
                  {{
                    p.isVideo == 1
                    ? "视频" : stringUtils.pictureGoodsFormat(p.type)
                  }}
                </code>
            </p>
          </a>
        </li>
      </ul>
      <input type="file" ref="videoFile" style="display: none" @change="dealfilechange" accept="video/*" />
    </div>
    <van-action-bar v-if="
      (options.goodsId == '0' || options.goods.step < 4) &&
      options.goods.status == 0
    ">
      <van-action-bar-button color="#ff2150" type="danger" @click="options.step = 2" text="返回" />
      <van-action-bar-button color="#ff2150" type="danger" @click="options.isUploadShow = true" text="添加" />
      <van-action-bar-button color="#ff2150" type="danger" @click="finish" text="完成" />
    </van-action-bar>
    <van-action-bar v-else>
      <van-action-bar-button color="#ff2150" type="danger" v-if="options.goods.status != 2 && options.goods.status != 1"
        @click="options.isUploadShow = true" text="添加" />
      <van-action-bar-button color="#ff2150" type="danger" v-if="options.goods.status != 2 && options.goods.status != 1"
        @click="finish" text="完成" />
    </van-action-bar>
  </div>
  <van-popup v-model:show="options.isTypeShow" round position="bottom">
    <van-cascader v-model="options.goods.typeId" title="请选择商品类型" :options="options.types"
      @close="options.isTypeShow = false" @finish="onFinishType" />
  </van-popup>
  <van-popup v-model:show="options.isUntiShow" round position="bottom">
    <van-cascader v-model="options.goods.unit" title="请选择商品单位" :options="options.units"
      @close="options.isUntiShow = false" @finish="onFinish" />
  </van-popup>
  <van-popup v-model:show="options.isPriceShow" :overlay="false" round position="bottom">
    <van-number-keyboard v-if="options.isPriceShow" v-model="options.goods.price" :z-index="2008" theme="custom"
      extra-key="." :hide-on-click-outside="true" close-button-text="完成" :show="options.isPriceShow"
      @blur="options.isPriceShow = false" />
  </van-popup>

  <van-popup v-model:show="options.isRotationShow" :style="{ height: '60vh' }" position="bottom">
    <van-divider :style="{ color: '#ff2150', borderColor: '#ff2150', padding: '0 16px' }">
      添加图片
    </van-divider>
    <img @click="options.isRotationShow = false" src="images/close.png"
      style="float: right; margin-top: -3rem; width: 1rem" />
    <van-uploader v-model="options.fileList" :capture="'camera'" :after-read="rotationMiddle" />
    <div class="sx_3">
      <a href="javascript:;" @click="savePricture">确认</a>
    </div>
  </van-popup>
  <van-dialog v-model:show="options.isUploadShow" title="上传拍品图片或视频" @confirm="setWxRead" show-cancel-button>
    <div class="paysuccess">
      <div class="pay2">
        <a href="javascript:;" @click="wxOptions.picType = 'list'"
          :class="wxOptions.picType == 'list' ? 'on' : 'seedd'">首图</a>
        <a href="javascript:;" @click="wxOptions.picType = 'rotation'"
          :class="wxOptions.picType == 'rotation' ? 'on' : 'seedd'">图片</a>
        <a href="javascript:;" @click="wxOptions.picType = 'video'"
          :class="wxOptions.picType == 'video' ? 'on' : 'seedd'">视频</a>
      </div>
    </div>
  </van-dialog>
  <van-dialog v-model:show="options.isPictureTypeShow" title="更换类型" @confirm="savePricture" show-cancel-button>
    <div class="paysuccess">
      <div class="pay1">
        <div class="pay1_R">
          <p class="p1">请选择类型</p>
          <p class="p2">
            首图：列表显示图片 轮播图：详细页滚动显示图片
            默认图：详细页详情中显示图片。
          </p>
        </div>
      </div>
      <div class="pay2">
        <a href="javascript:;" @click="options.picture.type = 'list'"
          :class="options.picture.type == 'list' ? 'on' : 'seedd'">首图</a>
        <a href="javascript:;" @click="options.picture.type = 'rotation'"
          :class="options.picture.type == 'rotation' ? 'on' : 'seedd'">轮播图</a>
        <a href="javascript:;" @click="options.picture.type = 'default'"
          :class="options.picture.type == 'default' ? 'on' : 'seedd'">默认图</a>
      </div>
    </div>
  </van-dialog>
  <van-dialog v-model:show="wxOptions.isWxMessageShow" :showConfirmButton="false" title="上传中">
    <div style="width: 100%">
      <div style="margin-left: 1rem; margin-bottom: 2rem; float: left; width: 40%">
        <van-circle v-model:current-rate="wxOptions.percentage" :rate="100" start-position="top" :speed="5"
          :color="wxOptions.gradientColor" :text="wxOptions.percentage + '%'" />
      </div>
      <code style="float: left; width: 50%; color: #ff2150; line-height: 5rem">{{ wxOptions.message }}</code>
    </div>
  </van-dialog>
  <van-image-preview v-model:show="options.isPreviewShow" :startPosition="options.imgIndex" :images="options.images"
    @change="onChange">
    <template v-slot:index>第{{ options.imgIndex + 1 }}幅</template>
  </van-image-preview>
  <van-popup v-model:show="options.isVideoShow" position="top" :style="{ width: '100%', height: '100vh', opacity: 0.9 }">
    <div style="">
      <span style="
            z-index: 10000;
            position: fixed;
            top: 0;
            right: 0;
            background-color: #fff;
            opacity:0.8
          "><img @click="options.isVideoShow = false" src="images/close.png" /></span>

      <video
        style="position: absolute;  top:50%;  left:50%;  transform: translate(-50%,-50%);  -webkit-transform: translate(-50%,-50%);  width:100%;  height: auto;"
        autoplay controls>
        <source :src="options.videoUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </van-popup>
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import filesApi from "@/api/filesApi";
import goodsApi from "@/api/goodsApi";
import goodsPrictureApi from "@/api/goodsPictureApi";
import goodsInfoApi from "@/api/goodsInfoApi";
import stringUtils from "@/utils/stringUtils.js";
import wxModel from "@/model/wxModel";
import videoUpload from "@/model/videoUpload";
import memberModel from "@/model/memberModel";
import { Toast, Dialog } from "vant";
export default {
  setup() {
    const route = useRoute();
    const options = reactive({
      goods: {
        id: "",
        title: "",
        typeId: "",
        typeName: "",
        weight: "",
        pictureSmall: "",
        pictureMiddle: "",
        pictureBig: "",
        unit: "",
        unitName: "",
        brand: "",
        tabs: "",
        price: "",
        preferentialPrice: "",
        describe: "<p></p>",
        introduction: "",
        isFinish: false,
        step: 0,
        status: 0,
        isMail: 0,
        mailPrice: ''
      },
      videoUrl: "",
      images: [],
      picture: {
        id: "",
        pictureSmall: "",
        pictureMiddle: "",
        pictureBig: "",
        productId: "",
        type: "rotation",
        typeName: "图片",
      },
      productInfo: [],
      pictureTypes: [],
      pictureList: [],
      units: [
        { text: "幅", value: 0 },
        { text: "盒", value: 1 },
        { text: "箱", value: 2 },
        { text: "个", value: 3 },
        { text: "支", value: 4 },
        { text: "双", value: 5 },
        { text: "瓶", value: 6 },
        { text: "件", value: 7 },
      ],
      types: [],
      imgIndex: 0,
      step: 0,
      isShow: false,
      isTypeShow: false,
      isPriceShow: false,
      isPictureTypeShow: false,
      isPreviewShow: false,
      isUntiShow: false,
      isNumberShow: false,
      isRotationShow: false,
      isUploadShow: false,
      isVideoShow: false,
      cascaderValue: "",
      unitCValue: "",
      unitFValue: "",
      fieldValue: "",
      status: 200,
      message: "",
      describeValue: "<p><img src='/images/xq1.png' /></p>",
      goodsId: route.params.id,
      loginSrc: "pc",
    });
    const { isLogin } = memberModel();
    const store = useStore();
    options.types = computed(() => store.state.common.goodTypes);
    options.pictureTypes = computed(() => store.state.common.pictureTypes);
    options.loginSrc = computed(() => store.getters.getLoginSrc);
    const { wxOptions, initConfig, chooseImage } = wxModel(getGoodsPricture);
    const { videoFile, upload, dealfilechange } = videoUpload(getGoodsPricture);
    const router = useRouter();
    if (route.params.id != "0") {
      options.goods.id = route.params.id;
      get();
      getGoodsPricture();
    } else {
      options.goods.id = "";
    }
    if (options.loginSrc == "wx") {
      initConfig();
    }
    wxOptions.num = 0;
    wxOptions.percentage = 0;
    wxOptions.message = "";
    const setWxRead = () => {
      var count = 9;
      options.isUploadShow = false;
      wxOptions.num = 0;
      wxOptions.percentage = 0;
      wxOptions.message = "开始上传";
      if (wxOptions.picType == "video") {
        upload(options.goods.id);
      } else {
        if (wxOptions.picType == "list") {
          count = 1;
        }
        wxOptions.isWxMessageShow = true;
        wxOptions.num = 0;
        wxOptions.percentage = 0;
        chooseImage("goods", count, {
          id: "",
          pictureSmall: "",
          pictureMiddle: "",
          pictureBig: "",
          productId: options.goods.id,
          type: wxOptions.picType,
          isVideo: 0,
        });
      }
    };
    const payVideo = (url) => {
      options.videoUrl = url;
      options.isVideoShow = true;
    };
    const onChange = (newIndex) => {
      options.imgIndex = newIndex;
    };
    const openPreview = (index) => {
      options.imgIndex = index;
      options.isPreviewShow = true;
    };
    const changePictureType = (item) => {
      options.picture = item;
      options.isPictureTypeShow = true;
    };
    const afterRead = (file) => {
      console.log(file);
      filesApi.uploadImg({ type: "goods", upfile: file.file }).then((res) => {
        if (res.status == 200) {
          options.describeValue = '<p><img src="' + res.result.url + '" /></p>';
          options.goods.describe = options.describeValue;
        }
      });
    };
    const delPricture = (id) => {
      console.log(id);
      Dialog.confirm({
        title: "删除图片",
        message: "亲，您确认要删除此图片?",
      }).then(() => {
        goodsApi.delPicture({ id: id }).then((res) => {
          if (res.status == 200) {
            getGoodsPricture();
          }
        });
      });
    };
    const afterReadMiddle = (file) => {
      console.log(file);
      filesApi.uploadImg({ type: "goods", upfile: file.file }).then((res) => {
        if (res.status == 200) {
          options.goods.pictureMiddle = res.result.url;
        }
      });
    };
    const rotationMiddle = (file) => {
      console.log(file);
      Toast(file);
      filesApi.uploadImg({ type: "goods", upfile: file.file }).then((res) => {
        if (res.status == 200) {
          options.picture.pictureMiddle = res.result.url;
          savePricture();
        }
      });
    };
    async function setInfo(item, v) {
      var params = {
        id: item.infoId,
        key: item.key,
        value: v,
        productId: options.goods.id,
        sort: item.sort,
      };
      if (params.id == "") {
        await goodsInfoApi.add(params).then((res) => {
          if (res.status == 200) {
            options.status = res.status;
            options.message = res.message;
          }
        });
      } else {
        await goodsInfoApi.update(params).then((res) => {
          if (res.status == 200) {
            options.status = res.status;
            options.message = res.message;
          }
        });
      }
      if (options.status != 200) {
        Toast.fail(options.message);
      } else {
        getInfo();
        Toast.success("保存成功");
      }
    }
    function get() {
      goodsApi.get({ id: options.goods.id }).then((res) => {
        if (res.status == 200) {
          options.goods = res.result;
          if (options.goods.price == 0) {
            options.goods.price = "";
          }
          if (options.goods.preferentialPrice == 0) {
            options.goods.preferentialPrice = "";
          }
          if (options.goods.step == 4) {
            options.step = 0;
          } else {
            options.step = options.goods.step;
          }

          options.describeValue =
            options.goods.describe == "<p></p>" || options.goods.describe == ""
              ? '<p><img src="/images/xq1.png" /></p>'
              : options.goods.describe;
          options.goods.unitName = stringUtils.unitFormat(options.goods.unit);
          getInfo();
        }
      });
    }
    function finish() {
      options.goods.isFinish = true;
      options.goods.step = 4;
      Dialog.confirm({
        title: "完成",
        message: "亲，您确认要完成【" + options.goods.title + "】的拍品？",
      }).then(() => {
        goodsApi.finish({ id: options.goods.id }).then((res) => {
          if (res.status != 200) {
            Toast.fail(res.message);
          } else {
            options.goods = res.result;
            options.isShow = false;
            router.push({ path: "/goodsSuccess/" + options.goods.id });
          }
        });
      });
    }
    const getInfo = () => {
      goodsInfoApi
        .getSetContext({ productId: options.goods.id })
        .then((res) => {
          if (res.status == 200) {
            options.productInfo = res.result;
          }
        });
    };
    async function save() {
      console.log(options.goods.id);
      console.log("modify");
      options.goods.preferentialPrice = options.goods.price;
      options.goods.step = options.step;
      await goodsApi.update(options.goods).then((res) => {
        options.goods = res.result;
        options.goods.unitName = stringUtils.unitFormat(options.goods.unit);
        options.status = res.status;
        options.message = res.message;
        options.isShow = false;
      });
      if (options.status != 200) {
        Toast.fail(options.message);
      } else {
        Toast.success("保存成功");
      }
    }
    function stepPrictureInit() {
      options.step = 3;
      getGoodsPricture();
    }
    function getGoodsPricture() {
      options.images = [];
      goodsPrictureApi
        .getPrictureForGoodsId({ id: options.goods.id })
        .then((res) => {
          if (res.status == 200) {
            options.pictureList = res.result;
            options.pictureList.forEach((item) => {
              options.images.push(item.pictureMiddle);
            });
          } else {
            Toast.fail(options.message);
          }
        });
    }
    function editPriceture(item) {
      options.picture = item;
      options.picture.typeName = stringUtils.pictureGoodsFormat(item.type);
      options.isRotationShow = true;
    }
    function openPriceture() {
      options.picture = {
        id: "",
        pictureSmall: "",
        pictureMiddle: "",
        pictureBig: "",
        productId: options.goods.id,
        type: "default",
        typeName: "默认",
      };
      wxOptions.percentage=0;
      options.isRotationShow = true;
    }
    async function savePricture() {
      console.log(options.goods.id);
      options.isPictureTypeShow = false;
      options.picture.productId = options.goods.id;
      if (options.picture.id == "") {
        console.log("add");
        await goodsPrictureApi.add(options.picture).then((res) => {
          options.status = res.status;
          options.message = res.message;
          //options.isRotationShow = false;
        });
      } else {
        console.log("modify");
        await goodsPrictureApi.update(options.picture).then((res) => {
          options.status = res.status;
          options.message = res.message;
          //options.isRotationShow = false;
        });
      }
      if (options.status != 200) {
        Toast.fail(options.message);
      } else {
        getGoodsPricture();
        if (options.picture.type == "list") {
          get();
        }
      }
    }
    async function next(num) {
      console.log(options.goods.id);
      if (options.step == 3) {
        options.goods.isFinish = true;
      } else {
        options.goods.isFinish = false;
      }
      if (num == -1) {
        options.step = options.step - 1;
        options.step = options.step == 0 ? 0 : options.step - 1;
        return;
      }
      if (options.goods.title == "") {
        Toast.fail("请添加拍品名称！");
        return;
      }
      if (options.goods.typeId == "") {
        Toast.fail("请选择拍品类型！");
        return;
      }
      if (options.goods.unit === "") {
        Toast.fail("请选择拍品单位！");
        return;
      }
      if (options.goods.price == "") {
        options.goods.price = 0;
      }
      if (
        options.goods.preferentialPrice == "" ||
        options.goods.preferentialPrice == null
      ) {
        options.goods.preferentialPrice = 0;
      }
      options.goods.step = options.step + 1;
      if (options.goods.id == "") {
        console.log("add");
        await goodsApi.add(options.goods).then((res) => {
          if (res.status == 200) {
            options.goods = res.result;
            if (options.goods.weight == 0) {
              options.goods.weight = "";
            }
            if (options.goods.price == 0) {
              options.goods.price = "";
            }
            if (options.goods.preferentialPrice == 0) {
              options.goods.preferentialPrice = "";
            }
            if (route.params.id == "0") {
              router.push({ path: "/goodsEdit/" + options.goods.id});
            }
          }
          options.status = res.status;
          options.message = res.message;
          options.isShow = false;
        });
      } else {
        console.log("modify");
        await goodsApi.update(options.goods).then((res) => {
          if (res.status == 200) {
            options.goods = res.result;
            if (options.goods.weight == 0) {
              options.goods.weight = "";
            }
            if (options.goods.price == 0) {
              options.goods.price = "";
            }
            if (options.goods.preferentialPrice == 0) {
              options.goods.preferentialPrice = "";
            }
          }
          options.status = res.status;
          options.message = res.message;
          options.isShow = false;
        });
      }
      if (options.status != 200) {
        Toast.fail(options.message);
      } else {
        options.goods.unitName = stringUtils.unitFormat(options.goods.unit);
        if (num == 1) {
          options.step = options.step + 1;
          if (options.step == 2) {
            getInfo();
          } else if (options.step == 3) {
            getGoodsPricture();
          } else if (options.step > 3) {
            router.push({ path: "/goodsSuccess/" + options.goods.id });
          }
        }
      }
    }
    function onFinish({ selectedOptions, value }) {
      options.goods.unit = value;
      options.goods.unitName = selectedOptions
        .map((option) => option.text)
        .join();
      options.isUntiShow = false;
    }
    function onFinishType({ selectedOptions, value }) {
      console.log(value);
      options.goods.typeName = selectedOptions
        .map((option) => option.text)
        .join("/");
      options.goods.typeId = value;
      options.isTypeShow = false;
    }
    function onFinishPrictureType({ selectedOptions, value }) {
      console.log(value);
      options.picture.typeName = selectedOptions
        .map((option) => option.text)
        .join("/");
      options.picture.type = value;
      options.isPictureTypeShow = false;
    }
    isLogin();
    return {
      options,
      onFinish,
      next,
      save,
      afterRead,
      afterReadMiddle,
      onFinishType,
      savePricture,
      stepPrictureInit,
      rotationMiddle,
      openPriceture,
      editPriceture,
      onFinishPrictureType,
      setInfo,
      finish,
      delPricture,
      setWxRead,
      changePictureType,
      onChange,
      openPreview,
      payVideo,
      dealfilechange,
      videoFile,
      wxOptions,
    };
  },
};
</script>

<style>
.van-cell {
  padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding) !important;
}

.onCurr {
  color: #fff !important;
  background: #ff2150 !important;
}

.info input {
  border: 0 !important;;
  margin-left: 3rem !important;
  background: #eee !important;
  color: #333 !important;
  border-radius: 3px !important;
  font-size: 0.75rem !important;
  width: 30% !important;
  height: 1.4rem !important;
  line-height: 1.4rem !important;
}
.van-field__label {
  width: 30% !important;
}

.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}

.upload {
  background: #ff2150;
  padding: 0.1rem 0.3rem;
  color: #fff;
  margin-left: 0.3rem;
  font-size: 0.6rem;
  -webkit-text-size-adjust: none;
}

.paysuccess .pay2 a.seedd {
  width: 4rem !important;
  margin-left: 0.5rem;
  float: left;
}

.paysuccess .pay2 a.on {
  width: 4rem !important;
  margin-left: 0.5rem;
  background-color: #ee0a24;
  color: #fff;
  float: left;
}

.paysuccess .pay2 {
  width: 100% !important;
  margin-left: 3rem !important;
}

.custom-button {
  width: 26px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #ee0a24;
  border-radius: 100px;
}

.goods_img {
  display: block;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.goods_step {
  padding: 0.5rem 0;
  height: 1.8rem;
  position: fixed;
  width: 100%;
  max-width: 640px;
  top: 2.2rem;
  background: #fff;
  z-index: 2000;
  border-bottom: 1px solid #eee;
}

.goods_edit {
  margin-top: 0.2rem;
  right: 0px;
  height: 78vh;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.addressDiv {
  margin-top: 2.6rem !important;
}
</style>