
const rtcHandle = () => {
    const client = new Object();
    let pc = null;
    client.createPeer = function (iceServer,auctionId) {
        console.log(iceServer);
        pc = new RTCPeerConnection();
        pc.roomId=auctionId;
    }
    client.addICEEventListener = function () {
        pc.onicecandidate = function (event) {
            if (event.candidate !== null) {
                console.log('onicecandidate: ', event.candidate);
            }
        };
    }
    client.onICEEventListener = function () {

    }
    client.addStreamEventListener = function (back) {
        pc.onaddstream = function (event) {
            console.log('pc.onaddstream');
            back(event.stream);
        };
    };
    client.onOfferEventListener = function (back) {
        // client.socket.on('rtc-offer-' + pc.roomId, function (offer) {
        //     console.log("接受offer：" + JSON.stringify(offer));
        //     back(offer);
        // });
        back();
    };
    client.onAnswerEnentListener = function (back) {
        client.socket.on('rtc-answer-' + pc.roomId, function (answer) {
            console.log("接受answer setlocal androw: " + JSON.stringify(answer));
            back(answer);
        });
    }
    client.setRemote = function (sdp) {
        pc.setRemoteDescription(new RTCSessionDescription(sdp));
    }
    client.createOfferFc = function (back) {
        pc.createOffer(function (desc) {
            console.log("发送offer setlocal androw: " + desc);
            pc.setLocalDescription(desc, function () {
                back(desc)
            });
        },
            function (err) {
                console.log(err);
            });
    }
    client.createAnswerFc = function () {
        pc.createAnswer(function (desc) {
            pc.setLocalDescription(desc, function () {
                console.log("发送answer setlocal androw");
                //back(desc);
            });
        }, function (error) {
            console.log('Failure callback: ' + error);
        });
    }
    client.createStream = function (config, back) {
        navigator.mediaDevices.getUserMedia(config)
            .then(function (stream) {
                var audioTracks = stream.getAudioTracks();
                console.log('Got stream with constraints:', config);
                console.log('Using video device: ' + audioTracks[0].label);
                stream.onended = function () {
                    console.log('Stream ended');
                };
                pc.addStream(stream);
                back(stream);
            })
            .catch(function (error) {
                if (error.name === 'ConstraintNotSatisfiedError') {
                    console.log('The resolution ' + config.audio.width.exact + 'x' +
                        config.audio.width.exact + ' px is not supported by your device.');
                } else if (error.name === 'PermissionDeniedError') {
                    console.log('Permissions have not been granted to use your camera and ' +
                        'microphone, you need to allow the page access to your devices in ' +
                        'order for the demo to work.');
                }
                console.log('getUserMedia error: ' + error.name, error);
            });
    }
    client.close = function (back) {
        pc.close();
        pc=null;
        back();
    }
    return {
        client
    }
}
export default rtcHandle