<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>积分兑换商品</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="hbox"></div>
  <a name="m1">
    <div class="xqbox1">
      <van-swipe :autoplay="3000" lazy-render style="height: 50vh">
        <van-swipe-item v-for="(image, index) in options.imgs" :key="image">
          <img
            :src="image.pictureMiddle"
            style="width: 100%; object-fit: cover"
            @click="openAPreview(index)"
          />
          <span
            style="
              position: fixed;
              z-index: 100000;
              right: -2rem;
              top: 16rem;
              width: 6rem;
            "
            v-if="image.isVideo == 1"
            @click="payVideo(image.pictureBig)"
            ><van-icon size="3rem" color="#1989fa" name="play-circle-o"
          /></span>
        </van-swipe-item>
      </van-swipe>
      <p class="tit">
        {{ options.item.goodsName }}
      </p>
      <div class="qita">
        <p class="p1"><span>兑换积分</span> {{ options.item.points }}</p>
        <p class="p2">全国包邮</p>
      </div>
    </div>
  </a>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div
    class="xqtab"
    v-show="
      options.item.introduction != '' && options.item.introduction != null
    "
  >
    <div class="Menubox">
      <ul>
        <li class="hover">商品介绍</li>
      </ul>
    </div>
    <div class="Contentbox">
      <div id="con_two_1">
        <div
          class="xqsub"
          style="
            min-height: 5rem;
            text-indent: 20px;
            margin-top: 0.5rem;
            padding: 0 0.1rem 0 0.1rem;
            background: #fff;
          "
        >
          {{ options.item.introduction }}
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="xqtab" v-if="options.goodsInfos.length > 0">
    <div class="Menubox">
      <ul>
        <li class="hover">商品参数</li>
        <!-- <li class=""  id="two3">热卖推荐</li> -->
      </ul>
    </div>
    <div class="Contentbox">
      <div id="con_two_2" style="margin-bottom: 2.6rem">
        <div class="canshu" v-for="info in options.goodsInfos" :key="info.id">
          <div class="canshu_1">
            <p class="pl" style="text-align: right; overflow: hidden">
              {{ info.key }}
            </p>
            <p class="pr" style="padding-left: 0.5rem; overflow: hidden">
              {{ info.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="clear"></div>
  <a name="m2"><div class="kbox"></div></a>
  <div class="clear"></div>
  <div class="xqbotbox">
    <div class="xqbotbox0">
      <div class="xqbotboxL">
        <ul>
          <li>
            <router-link to="/">
              <van-icon name="wap-home-o" size="1.8rem" />
              <p>首页</p>
            </router-link>
          </li>
          <li>
            <router-link :to="'/pointsGoodsList'">
              <van-icon name="coupon-o" size="1.8rem" />
              <p>列表</p>
            </router-link>
          </li>
          <li>
            <router-link :to="'/order'">
              <van-icon name="todo-list-o" size="1.8rem" />
              <p>订单</p>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="xqbotboxR">
        <a class="a2 sizetype" @click="pay">积分兑换</a>
      </div>
    </div>
  </div>
  <van-popup
    v-model:show="options.isVideoShow"
    position="top"
    :style="{ width: '100%', height: '100vh', opacity: 0.9 }"
  >
    <div style="">
      <span
        style="
          z-index: 10000;
          position: fixed;
          top: 0;
          right: 0;
          background-color: #fff;
          opacity: 0.8;
        "
        ><img @click="options.isVideoShow = false" src="images/close.png"
      /></span>

      <video
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          width: 100%;
          height: auto;
        "
        autoplay
        controls
      >
        <source :src="options.videoUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </van-popup>
  <van-image-preview
    v-model:show="options.isImgPreviewShow"
    :startPosition="options.aimgIndex"
    :images="options.viewImg"
    @change="onAChange"
  >
    <template v-slot:index>第{{ options.aimgIndex + 1 }}幅</template>
  </van-image-preview>
  <to-top></to-top>
</template>

<script>
import { reactive } from "@vue/reactivity";
import pointsApi from "@/api/pointsApi";
import { useRoute } from "vue-router";
import goodsApi from "@/api/goodsApi";
import goodsInfoApi from "@/api/goodsInfoApi";
import { Toast } from "vant";
import ToTop from '@/components/ToTop.vue';
export default {
  components: { ToTop },
  setup() {
    const route = useRoute();
    const options = reactive({
      item: {
        id: route.params.id,
        goodsId: "",
      },
      goodsInfos: [],
      imgs: [],
      viewImg: [],
      isImgPreviewShow: false,
      isVideoShow: false,
      aimgIndex: 0,
      videoUrl: "",
    });
    const rotation = () => {
      options.viewImg = [];
      goodsApi.getPicture({ goodsId: options.item.goodsId }).then((res) => {
        if (res.status == 200) {
          options.imgs = res.result;
          options.imgs.forEach((item) => {
            options.viewImg.push(item.pictureMiddle);
          });
        }
      });
    };
    const openAPreview = (index) => {
      options.aimgIndex = index;
      options.isImgPreviewShow = true;
    };
    const getInfo = () => {
      goodsInfoApi
        .getInfoList({ productId: options.item.goodsId })
        .then((res) => {
          if (res.status == 200) {
            options.goodsInfos = res.result;
          }
        });
    };
    const onAChange = (newIndex) => {
      options.aimgIndex = newIndex;
    };
    const payVideo = (url) => {
      options.videoUrl = url;
      options.isVideoShow = true;
    };
    const get = () => {
      pointsApi.getGoods({ id: route.params.id }).then((res) => {
        if (res.status == 200) {
          options.item = res.result;
          getInfo();
          rotation();
        }
      });
    };
    const pay = () => {
      pointsApi.payGoods({ goodsId: route.params.id }).then((res) => {
        if (res.status == 200) {
          const toast = Toast.loading({
            duration: 0,
            forbidClick: true,
            message: "",
          });
          let second = 3;
          const timer = setInterval(() => {
            second--;
            if (second) {
              toast.message = '处理中'+second+'秒';
            } else {
              clearInterval(timer);
              Toast.clear();
            }
          }, 1000);
        } else {
          Toast.fail(res.message);
        }
      });
    };
    get();
    return {
      options,
      onAChange,
      openAPreview,
      payVideo,
      pay
    };
  },
};
</script>

<style>
</style>