<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>拍品管理</p>
      </div>
      <div class="headerR">
        <router-link to="/goodsEdit/0">添加</router-link>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="shopType" style="top: 2.8rem !important; max-width: 640px">
    <ul>
      <li :class="options.handle == '' ? 'on' : ''" @click="setOperate('')">
        <a href="javascript:;">全部({{ options.totalCount.ALL }})</a>
      </li>
      <li
        :class="options.handle == 'NOSUMIT' ? 'on' : ''"
        @click="setOperate('NOSUMIT')"
      >
        <a href="javascript:;">未完成({{ options.totalCount.NOSUMIT }})</a>
      </li>
      <li
        :class="options.handle == 'FREE' ? 'on' : ''"
        @click="setOperate('FREE')"
      >
        <a href="javascript:;">可发布({{ options.totalCount.FREE }})</a>
      </li>
      <li
        :class="options.handle == 'AUCTION' ? 'on' : ''"
        @click="setOperate('AUCTION')"
      >
        <a href="javascript:;">拍卖({{ options.totalCount.AUCTION }})</a>
      </li>
      <li style="float: right">
        <a href="javascript:;" class="a_sx" @click="options.screenShow = true"
          >筛选</a
        >
      </li>
    </ul>
  </div>
  <div class="clear"></div>
  <div class="hbox1"></div>
  <to-top></to-top>
  <div class="kbox"></div>
  <div class="kbox"></div>
  <div class="myddcon">
    <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="options.loading"
        :finished="options.finished"
        :offset="1200"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="myddcon1" v-for="g in options.list" :key="g.id">
          <div class="dpbox">
            <div class="dpL">
              <router-link :to="'/goodsEdit/' + g.id">
                <span>{{ g.title }}</span>
                <img src="images/mre1.png" />
              </router-link>
            </div>
            <div class="dpR" v-if="g.operate == 0">
              <a href="javascript:;" v-if="g.status == 0">待提交</a>
              <!-- <a href="javascript:;" v-if="g.status == 1">待审核</a>
              <a href="javascript:;" v-if="g.status == 2">审核成功</a>
              <a href="javascript:;" v-if="g.status == 3">审核失败</a> -->
            </div>
            <div class="dpR" v-else-if="g.operate == 1">
              <a href="javascript:;">已发布到拍卖</a>
            </div>
            <div class="dpR" v-else-if="g.operate == 3">
              <a href="javascript:;">已发布到连续拍</a>
            </div>
          </div>
          <div class="shopbox">
            <div class="shopboxL">
              <!-- <img :src="g.pictureSmall" /> -->
              <van-image width="4rem" height="4rem" :src="g.pictureSmall" />
            </div>
            <div class="shopboxR">
              <div class="shopboxR_1">
                <div class="sbr1_1">{{ g.introduction }}</div>
                <div class="sbr1_2">
                  <p
                    class="p2"
                    v-if="
                      g.preferentialPrice != 0 &&
                      g.preferentialPrice == null &&
                      g.preferentialPrice == ''
                    "
                  >
                    ￥{{ g.preferentialPrice }}
                  </p>
                </div>
              </div>
              <div class="shopboxR_2">
                <p class="p3">
                  <code v-if="g.typeName != ''">类型：{{ g.typeName }}；</code>
                  <code v-if="g.tabs != ''">关键字：{{ g.tabs }}；</code>
                  <code v-if="g.brand != ''">品牌：{{ g.brand }}；</code>
                </p>
              </div>
            </div>
          </div>
          <div class="dpbtn">
            <div class="dpbtn1">
              <img src="images/dat.png" />
              <span>{{
                stringUtils.dateFormat(g.createTime, "YYYY-MM-DD HH:mm")
              }}</span>
            </div>
            <div class="dpbtn2" v-if="g.status == 0 || g.status == 3">
              <a href="javascript:;" @click="finish(g)">完成</a>
            </div>
            <div class="dpbtn2" v-if="g.status == 2 && g.operate == 0">
              <a href="javascript:;" @click="openAuction(g.id)">一键拍卖</a>
            </div>
            <div class="dpbtn2" v-if="g.status == 2 && g.operate == 1">
              <router-link :to="'/auctionDetail/' + g.operate_id"
                >查看拍卖</router-link
              >
            </div>
            <!-- <div class="dpbtn2">
         <router-link v-if="g.status==2" :to="'/goodsEdit/'+g.id">查看</router-link>
         <router-link v-else :to="'/goodsEdit/'+g.id">编辑</router-link>
        </div> -->
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
  <van-popup
    v-model:show="options.screenShow"
    position="right"
    :style="{ height: '100vh', width: '70%' }"
  >
    <div id="sxtj">
      <div class="sx_1">
        筛选<img @click="options.screenShow = false" src="images/close.png" />
      </div>
      <div class="sx_2">
        <p class="tit">商品名称</p>
        <van-field v-model="params.title" placeholder="请输商品名称" />
      </div>
      <div class="sx_2">
        <p class="tit">类别</p>
        <van-field
          v-model="params.typeName"
          is-link
          readonly
          placeholder="请选择商品类型"
          @click="options.isTypeShow = true"
        />
      </div>
      <div class="sx_3">
        <a href="javascript:;" @click="search">确定</a>
      </div>
    </div>
  </van-popup>
  <van-popup v-model:show="options.isTypeShow" round position="bottom">
    <van-cascader
      v-model="params.typeId"
      title="请选择商品类型"
      :options="options.types"
      @close="options.isTypeShow = false"
      @finish="onFinishType"
    />
  </van-popup>
  <van-popup v-model:show="options.isStatusShow" round position="bottom">
    <van-cascader
      v-model="params.status"
      title="请选择商品状态"
      :options="options.statusList"
      @close="options.isStatusShow = false"
      @finish="onFinish"
    />
  </van-popup>

  <van-popup
    v-if="okOptions.isFinishAuctionShow"
    v-model:show="okOptions.isFinishAuctionShow"
    position="bottom"
    :style="{ height: '100vh' }"
  >
    <div id="size" style="position: fixed;top: 0;">
      <div class="size1">
        <div class="size1_3" @click="okOptions.isFinishAuctionShow = false">
          <img src="images/close.png" />
        </div>
      </div>
    </div>
    <van-divider >一键拍卖</van-divider>
    <div class="addressDiv">
      <div class="addiv1">
        <van-radio-group
          v-model="okOptions.auctionParams.type"
          direction="horizontal"
        >
          <van-radio name="DEFAULT">普通拍卖</van-radio>
          <van-radio name="FIXED">一口价</van-radio>
        </van-radio-group>
      </div>
      <div class="addiv1" @click="okOptions.isFinishStartPriceShow = true">
        <div class="addiv1_l">
          {{ okOptions.auctionParams.type == "DEFAULT" ? "起拍价" : "售价" }}:
        </div>
        <div class="addiv1_r"  style="line-height:1rem;">
          <div style="width: 6rem;padding-left: .2rem;" >
            <span v-if="okOptions.auctionParams.startPrice==''">请输入金额</span>
            <span v-else>{{okOptions.auctionParams.startPrice}}</span>
          </div>
        </div>
      </div>
      <div
        class="addiv1"
        @click="okOptions.isFinishBidPriceShow = true"
        v-if="okOptions.auctionParams.type == 'DEFAULT'"
      >
        <div class="addiv1_l">加价金额:</div>
        <div class="addiv1_r" style="line-height:1rem;">
          <div style="width: 6rem;padding-left: .2rem;" >
            <span v-if="okOptions.auctionParams.bidAmount==''">请输加价金额</span>
            <span v-else>{{okOptions.auctionParams.bidAmount}}</span>
          </div>
        </div>
      </div>
      <div class="addiv1" @click="okOptions.showFinishPicker = true">
        <div class="addiv1_l">开始时间:</div>
        <div class="addiv1_r" style="line-height:1rem;">
          {{
            stringUtils.dateFormat(
              okOptions.auctionParams.startTime,
              "YYYY-MM-DD HH:mm"
            )
          }}
        </div>
      </div>
      <div class="addiv1" @click="okOptions.showFinishEndPicker = true">
        <div class="addiv1_l">结束时间:</div>
        <div class="addiv1_r" style="line-height:1rem;">
          {{
            stringUtils.dateFormat(
              okOptions.auctionParams.endTime,
              "YYYY-MM-DD HH:mm"
            )
          }}
        </div>
      </div>
      <div class="addiv1">
        <div class="addiv1_l">是否付邮费:</div>
        <div class="addiv1_r" style="padding-left: 1rem;line-height:1rem;">
          <van-switch
            :model-value="okOptions.isMail"
            size="1.1rem"
            @update:model-value="onUpdateMail"
          />
          <div v-if="okOptions.isMail" @click="okOptions.isMailShow=true" style="float:right;border: 1px solid #333;width: 6rem;" >
            <span v-if="okOptions.auctionParams.mailPrice==''">请输入邮费金额</span>
            <span v-else>{{okOptions.auctionParams.mailPrice}}</span>
          </div>
        </div>
      </div>
      <div class="addiv1" v-if="okOptions.auctionParams.type == 'DEFAULT'">
        <div class="addiv1_l">设置保证金:</div>
        <div class="addiv1_r" style="padding-left: 1rem;line-height:1rem;">
          <van-switch
            :model-value="okOptions.isBond"
            size="1.1rem"
            @update:model-value="onUpdateBond"
          />
          <div  v-if="okOptions.isBond&&okOptions.auctionParams.type == 'DEFAULT'" @click="okOptions.isBondShow=true"   style="float:right;border: 1px solid #333;width: 6rem;" >
            <span v-if="okOptions.auctionParams.bondPrice==''">请输入保证金金额</span>
            <span v-else>{{okOptions.auctionParams.bondPrice}}</span>
          </div>
        </div>
      </div>
      <div
        class="addiv1"
        style="height: 10vh"
        v-if="okOptions.auctionParams.type == 'DEFAULT'"
      >
        <div class="addiv1_l">延时设置:</div>
        <div class="addiv1_r">
          <div class="sx_2">
            <a
              :class="okOptions.auctionParams.delayedTime == 0 ? 'onCurr' : ''"
              href="javascript:;"
              @click="okOptions.auctionParams.delayedTime = 0"
              >不设置</a
            >
            <a
              :class="okOptions.auctionParams.delayedTime == 30 ? 'onCurr' : ''"
              @click="okOptions.auctionParams.delayedTime = 30"
              href="javascript:;"
              >30秒</a
            >
            <a
              :class="okOptions.auctionParams.delayedTime == 60 ? 'onCurr' : ''"
              @click="okOptions.auctionParams.delayedTime = 60"
              href="javascript:;"
              >1分钟</a
            >
            <a
              :class="
                okOptions.auctionParams.delayedTime == 180 ? 'onCurr' : ''
              "
              @click="okOptions.auctionParams.delayedTime = 180"
              href="javascript:;"
              >3分钟</a
            >
            <a
              :class="
                okOptions.auctionParams.delayedTime == 300 ? 'onCurr' : ''
              "
              @click="okOptions.auctionParams.delayedTime = 300"
              href="javascript:;"
              >5分钟</a
            >
            <a
              :class="
                okOptions.auctionParams.delayedTime == 600 ? 'onCurr' : ''
              "
              @click="okOptions.auctionParams.delayedTime = 600"
              href="javascript:;"
              >10分钟</a
            >
          </div>
        </div>
      </div>
      <div class="addiv1" style="height: 25vh">
        <div>
          <div
            class="redpacket"
            style="padding-top: 0.3rem; width: 100%; padding-bottom: 1rem"
          >
            <p
              style="
                text-indent: 0.4rem;
                margin-left: 1rem;
                margin-right: 0.5rem;
                font-size: 0.8rem;
                line-height: 1.5rem;
              "
            >
              拍卖成功后您愿意拿出拍品成交额的一定比例用来给参拍人员发红包吗？
            </p>
          </div>
          <div class="clear"></div>
          <div class="redpacket_change">
            <a
              :class="okOptions.auctionParams.amountRatio == 0 ? 'onCurr' : ''"
              href="javascript:;"
              @click="setRedPacket(false, 0)"
              >不发红包</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 1 ? 'onCurr' : ''"
              @click="setRedPacket(true, 1)"
              href="javascript:;"
              >1%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 2 ? 'onCurr' : ''"
              @click="setRedPacket(true, 2)"
              href="javascript:;"
              >2%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 3 ? 'onCurr' : ''"
              @click="setRedPacket(true, 3)"
              href="javascript:;"
              >3%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 4 ? 'onCurr' : ''"
              @click="setRedPacket(true, 4)"
              href="javascript:;"
              >4%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 5 ? 'onCurr' : ''"
              @click="setRedPacket(true, 5)"
              href="javascript:;"
              >5%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 6 ? 'onCurr' : ''"
              @click="setRedPacket(true, 6)"
              href="javascript:;"
              >6%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 7 ? 'onCurr' : ''"
              @click="setRedPacket(true, 7)"
              href="javascript:;"
              >7%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 8 ? 'onCurr' : ''"
              @click="setRedPacket(true, 8)"
              href="javascript:;"
              >8%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 9 ? 'onCurr' : ''"
              @click="setRedPacket(true, 9)"
              href="javascript:;"
              >9%</a
            >
            <a
              :class="okOptions.auctionParams.amountRatio == 10 ? 'onCurr' : ''"
              @click="setRedPacket(true, 10)"
              href="javascript:;"
              >10%</a
            >
          </div>
        </div>
       
      </div>
      
      <div class="size3" style="position: fixed;">
        <a href="javascript:;" @click="onekey">发布拍卖</a>
      </div>
    </div>
  </van-popup>
  <van-popup v-model:show="okOptions.isMailShow" :overlay="false" position="bottom">
    <van-number-keyboard
      v-if="okOptions.isMailShow"
      v-model="okOptions.auctionParams.mailPrice"
      theme="custom"
      extra-key="."
      :hide-on-click-outside="true"
      close-button-text="完成"
      :show="okOptions.isMailShow"
      @blur="okOptions.isMailShow = false"
    />
  </van-popup>
  
  <van-popup v-model:show="okOptions.isBondShow" :overlay="false" position="bottom">
    <van-number-keyboard
      v-if="okOptions.isBondShow"
      v-model="okOptions.auctionParams.bondPrice"
      theme="custom"
      extra-key="."
      :hide-on-click-outside="true"
      close-button-text="完成"
      :show="okOptions.isBondShow"
      @blur="okOptions.isBondShow = false"
    />
  </van-popup>
  <van-popup v-model:show="okOptions.isFinishStartPriceShow" :overlay="false" position="bottom">
    <van-number-keyboard
      v-if="okOptions.isFinishStartPriceShow"
      v-model="okOptions.auctionParams.startPrice"
      theme="custom"
      :z-index="2010"
      extra-key="."
      :hide-on-click-outside="true"
      close-button-text="完成"
      :show="okOptions.isFinishStartPriceShow"
      @blur="okOptions.isFinishStartPriceShow = false"
    />
  </van-popup>
  <van-popup v-model:show="okOptions.isFinishBidPriceShow" :overlay="false" position="bottom">
    <van-number-keyboard
      v-if="okOptions.isFinishBidPriceShow"
      v-model="okOptions.auctionParams.bidAmount"
      theme="custom"
      :z-index="2010"
      extra-key="."
      :hide-on-click-outside="true"
      close-button-text="完成"
      :show="okOptions.isFinishBidPriceShow"
      @blur="okOptions.isFinishBidPriceShow = false"
    />
  </van-popup>
  <van-popup v-model:show="okOptions.showFinishPicker" position="bottom">
    <van-datetime-picker
      type="datetime"
      :minDate="okOptions.minDate"
      @confirm="onStartConfirm"
      @cancel="okOptions.showFinishPicker = false"
    />
  </van-popup>
  <van-popup v-model:show="okOptions.showFinishEndPicker" position="bottom">
    <van-datetime-picker
      type="datetime"
      :minDate="okOptions.auctionParams.startTime"
      @confirm="onEndConfirm"
      @cancel="okOptions.showFinishEndPicker = false"
    />
  </van-popup>
</template>
<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import goodsApi from "@/api/goodsApi";
import oneKeyModel from "@/model/auction/onekeyModel";
import stringUtils from "@/utils/stringUtils.js";
import { Toast, Dialog } from "vant";
import ToTop from "@/components/ToTop.vue";
export default {
  setup() {
    const options = reactive({
      list: [],
      types: [],
      statusList: [
        { text: "清空", value: -1 },
        { text: "待提交", value: 0 },
        { text: "待审核", value: 1 },
        { text: "审核成功", value: 2 },
        { text: "审核失败", value: 3 },
      ],
      totalCount: {
        ALL: 0,
        FREE: 0,
        AUCTION: 0,
        SHOPPING: 0,
      },
      delayeds: [],
      item: {
        title: "",
      },
      screenShow: false,
      isTypeShow: false,
      isStatusShow: false,
      pageCount: 1,
      pageNo:1,
      refreshing: false,
      loading: false,
      finished: false,
      total: 0,
      handle: "",
    });
    const params = reactive({
      draw: 1,
      pageNo: 1,
      length: 10,
      title: "",
      typeId: "",
      typeName: "",
      status: "",
      statusName: "",
      operate: "",
    });
    const router = useRouter();
    const store = useStore();
    options.types = computed(() => store.state.common.goodTypes);
    options.delayeds = computed(() => store.state.common.delayeds);
    async function onRefresh() {
      options.finished = false;
      options.loading = true;
      options.refreshing = true;
      onLoad();
    }
    const searchDefault = () => {
      params.draw = 1;
      params.pageNo = 1;
      params.length = 10;
      params.title = "";
      params.typeId = "";
      params.typeName = "";
      params.status = "";
      params.statusName = "";
      params.operate = "";
      options.pageNo=1;
      onRefresh();
    };
    async function onLoad() {
      console.log(
        "refreshing:" + options.refreshing + ",start:" + params.start
      );
      if (options.refreshing) {
        options.list = [];
        options.pageCount = 1;
        options.pageNo=1;
        options.refreshing = false;
      }else{
        if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
      }
      params.pageNo = (options.pageNo - 1);
      console.log("start1:" + params.pageNo);
      params.pageNo = params.pageNo < 0 ? 0 : params.pageNo;
      if (
        options.list.length > 0 &&
        options.total > 0 &&
        options.list.length >= options.total
      ) {
        options.finished = true;
        return;
      }
      await goodsApi.searchMy(params).then((res) => {
        if (res.status == 200) {
          options.total = res.recordsTotal;
          let count = Math.ceil(res.recordsTotal / params.length);
          options.pageCount = count;
          res.data.forEach((item) => {
            if (options.list.length < res.recordsTotal) {
              options.list.push(item);
            }
          });
        }
        options.loading = false;
        if (options.list.length >= options.total) {
          options.finished = true;
        }
      });
    }
    function finish(goods) {
      goods.isFinish = true;
      goods.step = 4;
      Dialog.confirm({
        title: "完成",
        message: "亲，您确认要完成【" + goods.title + "】的拍品？",
      }).then(() => {
        goodsApi.finish({ id: goods.id }).then((res) => {
          if (res.status != 200) {
            Toast.fail(res.message);
            router.push({ path: "/goodsEdit/" + goods.id });
          } else {
            router.push({ path: "/goodsSuccess/" + goods.id });
          }
        });
      });
    }
    function submit(item) {
      Dialog.confirm({
        title: "提交审核",
        message: "亲，您确认要提交【" + item.title + "】的拍品审核？",
      }).then(() => {
        goodsApi.submit({ id: item.id }).then((res) => {
          if (res.status == 200) {
            onRefresh();
          } else {
            Toast.fail(res.message);
          }
        });
      });
    }
    function onFinishType({ selectedOptions, value }) {
      console.log(value);
      params.typeName = selectedOptions.map((option) => option.text).join("/");
      params.typeId = value;
      options.isTypeShow = false;
    }
    function onFinish({ value }) {
      console.log(value);
      if (value > -1) {
        params.status = value;
        params.statusName = stringUtils.statusFormat(value);
      } else {
        params.status = -1;
        params.statusName = "";
      }
      options.isStatusShow = false;
    }
    function del(item) {
      Dialog.confirm({
        title: "删除商品",
        message: "亲，您确认要删除商品【" + item.title + "】?",
      }).then(() => {
        // on confirm
        goodsApi.delete({ id: item.id }).then((res) => {
          if (res.status == 200) {
            onRefresh();
          } else {
            Toast.fail(res.message);
          }
        });
      });
    }
    const getTotalCount = () => {
      goodsApi.getTotal().then((res) => {
        if (res.status == 200) {
          options.totalCount = res.result;
        }
      });
    };
    const setOperate = (val) => {
      options.handle = val;
      if (val == "") {
        params.operate = "";
        params.status = "";
      } else if (val != "NOSUMIT") {
        params.status = 2;
        params.operate = val;
      } else {
        params.operate = "";
        params.status = 0;
      }
      onRefresh();
    };
    const {
      okOptions,
      openAuction,
      onekey,
      onStartConfirm,
      onDelayedFinish,
      onEndConfirm,
      setRedPacket,
      onUpdateMail,
      onUpdateBond,
    } = oneKeyModel(Toast, Dialog);
    getTotalCount();
    return {
      options,
      okOptions,
      params,
      del,
      onFinishType,
      onFinish,
      submit,
      onekey,
      openAuction,
      onStartConfirm,
      onEndConfirm,
      onDelayedFinish,
      setRedPacket,
      searchDefault,
      onRefresh,
      onLoad,
      finish,
      setOperate,
      onUpdateMail,
      onUpdateBond
    };
  },
  components: { ToTop },
};
</script>

<style>
#sxtj {
  position: relative;
  right: 0;
  width: 100%;
  height: 100vh;
}
.van-field__body input {
  width: 100% !important;
  text-align: center !important;
  color: #000 !important;
}
.van-cell {
  padding: 0 0 0 0;
}
.onCurr {
  color: #fff !important;
  background: #ff2150 !important;
}
.shopType {
  z-index: 2000;
}
.sx_1 img {
  float: right;
  width: 1rem;
  margin-top: 0.5rem;
}
#size {
  height: 0.3rem;
}
.addiv1_r {
  margin-top: 0.2rem;
  margin-left: 0.2rem;
}
.van-radio-group {
  margin-left: 6rem;
}
.custom-button {
  width: 26px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #ee0a24;
  border-radius: 100px;
}
.onCurr {
  color: #fff !important;
  background: #ff2150 !important;
}
.redpacket p {
  float: left;
}
.redpacket_change {
  width: 95%;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
}
.redpacket_change a {
  padding: 0.3rem 0.8rem;
  float: left;
  font-size: 0.75rem;
  white-space: nowrap;
  background: #eee;
  color: #666;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 3px;
  line-height: 1rem;
}
</style>