<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>积分兑换</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <!-- <div class="shopType">
    <ul>
      <li class="on">
        <a href="shoplist.html">综合</a>
      </li>
      <li>
        <a href="shoplist.html">销量</a>
      </li>
      <li>
        <a href="shoplist.html">价格</a>
        <span class="pricebtn1"></span>
        <span class="pricebtn2"></span>
      </li>
      <li>
        <a href="dplist.html">店铺</a>
      </li>
      <li>
        <a href="javascript:;" class="a_sx">筛选</a>
      </li>
    </ul>
  </div> -->
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="likebox">
    <ul>
      <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
        <van-list
          v-model:loading="options.loading"
          :finished="options.finished"
          :offset="200"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <li v-for="g in options.data" :key="g.id">
            <router-link :to="'/pointsGoods/'+g.id">
              <van-image
                width="11rem"
                height="12rem"
                style="object-fit: cover"
                :src="g.goodsPicture"
              />
              <p class="tit">{{g.goodsName}}</p>
              <p class="price">
                {{g.points}}积分<img src="images/my3.png" />
              </p>
            </router-link>
          </li>
        </van-list>
      </van-pull-refresh>
    </ul>
  </div>
    <to-top></to-top>
</template>

<script>
import { reactive } from "@vue/reactivity";
import configUtils from "@/config/configUtils";
import pointsApi from "@/api/pointsApi";
import ToTop from "@/components/ToTop.vue";
export default {
  components:{ToTop},
  setup() {
    const options = reactive({
      data: [],
      pageNo:1,
      pageCount: 1,
      refreshing: false,
      loading: false,
      finished: false,
      total: 0,
    });
    const params = reactive({
      draw: 1,
      pageNo: 0,
      length: 10,
      status: "",
    });
    const onRefresh=async ()=>{
      options.finished = false;
      options.loading = true;
      options.refreshing = true;
      onLoad();
    }
    const onLoad= async()=>{
      console.log("refreshing:" + options.refreshing);
      if (options.refreshing) {
        options.data = [];
        options.total=0;
        options.pageNo = 1;
        options.pageCount = 1;
        options.refreshing = false;
      }
      params.pageNo = (options.pageNo - 1) 
      if (
        options.data.length > 0 &&
        options.total > 0 &&
        options.data.length >= options.total
      ) {
        options.finished = true;
        return;
      }
      await pointsApi.searchGoods(params).then((res) => {
        if (res.status == 200) {
          options.total = res.recordsTotal;
          let count = Math.ceil(res.recordsTotal / configUtils.page.size);
          options.pageCount = count;
          if (res.recordsTotal == 0) {
            options.data = res.data;
          } else {
            res.data.forEach((item) => {
              if (options.data.length < res.recordsTotal) {
                options.data.push(item);
              }
            });
          }
        }
        options.loading = false;
        if (options.data.length >= options.total) {
          options.finished = true;
        }
      });
    }
    //const { isLogin } = memberModel();
    return {
        options,
        onRefresh,
        onLoad
    }
  },
};
</script>

<style>
</style>