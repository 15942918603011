<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
      </div>
      <div class="headerC">
        <p>我的拍卖</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="shopType" style="top: 2.9rem !important; max-width: 640px">
    <ul>
      <li :class="options.step == 'default' ? 'on' : ''">
        <a href="javascript:;" @click="defaultSearch">全部({{ options.totalCount.all }})</a>
      </li>
      <li :class="options.step == 'offline' ? 'on' : ''">
        <a href="javascript:;" @click="onlineSearch(0)">待发布({{ options.totalCount.offline }})</a>
      </li>
      <li :class="options.step == 'online' ? 'on' : ''">
        <a href="javascript:;" @click="onlineSearch(1)">已发布({{ options.totalCount.online }})</a>
      </li>
      <li :class="options.step == 'end' ? 'on' : ''">
        <a href="javascript:;" @click="endSearch">已结束({{ options.totalCount.end }})</a>
      </li>
      <li :class="options.step == 'copy' ? 'on' : ''">
        <a href="javascript:;" @click="copySearch">可重发({{ options.totalCount.copy }})</a>
      </li>
    </ul>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="myddcon">
    <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <van-list v-model:loading="options.loading" :finished="options.finished" :offset="200" finished-text="没有更多了"
        @load="onLoad">
        <div class="myddcon1" v-for="a in options.auctions" :key="a.id">
          <div class="dpbox">
            <div class="dpL">
              <router-link v-if="a.isOnline == 1" :to="'/auctionDetail/' + a.id">
                <span>{{ a.title }}</span>
                <img src="images/mre1.png" />
              </router-link>
              <a href="javascript:;" v-else>
                <span>{{ a.title }}</span>
                <img src="images/mre1.png" />
              </a>
            </div>
            <div class="dpR">
              <a href="javascript:;" v-if="a.isOnline == 1 && a.bidStatus == 0">待开始</a>
              <a href="javascript:;" v-if="a.bidStatus == 1 && a.status == 2">拍卖中</a>
              <a href="javascript:;" v-if="a.bidStatus == 1 && a.status == 6">拍卖结束</a>
              <a href="javascript:;" v-if="a.status == 7">已复制</a>
            </div>
          </div>
          <div class="shopbox" v-for="g in a.goods" :key="g.id">
            <div class="shopboxL">
              <van-image width="4rem" height="4rem" :src="g.goodsPicture" />
            </div>
            <div class="shopboxR">
              <div class="shopboxR_1">
                <div class="sbr1_1">{{ g.goodsName }}</div>
                <div class="sbr1_2">
                  <b v-if="a.status == 6 && g.result == 2">流拍</b>
                </div>
              </div>
              <div class="shopboxR_2">
                <p class="p3" v-if="a.type != 'FIXED'">
                  起拍价：￥{{ g.startPrice }} 加价金额：￥{{ g.bidAmount }}
                </p>
                <p class="p3" v-else>一口价：￥{{ g.startPrice }}</p>
              </div>
            </div>
          </div>
          <div class="dphjbox">
            <p class="p5" style="width:6rem;float:right;" v-if="a.delayedRule != null && a.delayedRule.isAvailable == 1">
              延时：{{ a.delayedRule.delayTime }}秒/次
            </p>
            <p class="p5" style="width:6rem;float:right;" v-if="a.bondRule != null && a.bondRule.isAvailable == 1">
              保证金：￥{{ a.bondRule.amount }}
            </p>
          </div>
          <div class="dpbtn">
            <div class="dpbtn1" style="width: 60%">


              <span style="color: red" v-if="a.isBond == 1 && (a.isMerchantBond == 0 || a.isMerchantBond == null)"> 保证金未交</span>
              <span v-else-if="a.isOnline == 1&&a.status==2">
                <vab-count-down :startTime="a.startTime" :endTime="a.endTime"></vab-count-down></span>
              <span v-else style="color: red">未发布</span>
              <span style="color: #56a536" v-if="a.isBond == 1 && a.isMerchantBond == 1"> 保证金已交</span>
            </div>
            <div class="dpbtn2" style="width: 40%">
              <router-link v-if="a.status == 6 && options.params.isCopy != 1" to="/merchantOrder">查看订单</router-link>
              <a href="javascript:;" v-if="a.status == 6 && options.params.isCopy == 1" @click="copy(a)">复制</a>
              <a href="javascript:;"
                v-if="((a.isOnline == 0 && a.isBond == 0) || (a.isOnline == 0 && a.isBond == 1 && a.isMerchantBond == 1)) && a.status == 2"
                @click="openOnline(a)">发布</a>
              <router-link v-if="a.isBond == 1 && a.isMerchantBond != 1 && a.status == 2" class="a2 sizetype"
                :to="'/pay/MBOND/' + a.id">保证金</router-link>
              <a href="javascript:;" v-if="a.isOnline == 0 && (a.isMerchantBond == 0 || a.isMerchantBond == null) && a.status == 2"
                @click="openModify(a.id)">修改</a>
              <a href="javascript:;" v-if="a.status < 6 && a.bidStatus == 0 && a.isOnline == 1" @click="offline(a)">下线</a>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
  <van-popup v-if="options.isModifyAuctionShow" v-model:show="options.isModifyAuctionShow" position="bottom"
    :style="{ height: '95vh', width: '100%' }">
    <div class="xzsize">
      <div class="xzsize0">
        <div class="f_mask0"></div>
        <div id="size">
          <div class="size1">
            <div class="size1_1">
              <van-image width="10rem" height="10rem" class="proimg" style="object-fit: cover"
                :src="options.auction.pictureSmall" />
            </div>
            <div class="size1_2">
              <p class="p1">
                标题：{{ options.auction.title }}
              </p>
              <p class="p2">
                时间：{{
                  stringUtils.dateFormat(
                    options.auction.startTime,
                    "yyyy-MM-DD HH:mm"
                  )
                }}
                至
                {{
                  stringUtils.dateFormat(
                    options.auction.endTime,
                    "yyyy-MM-DD HH:mm"
                  )
                }}
              </p>
              <p class="p3">
                类型：{{ stringUtils.auctionTypeFormat(options.auction.type) }}
              </p>
            </div>
            <div class="size1_3">
              <img src="images/close.png" @click="options.isModifyAuctionShow = false" />
            </div>
          </div>
          <div class="size2">
            <div class="size2_3" @click="options.isFinishStartPriceShow = true">
              <p class="tit">
                {{ options.auction.type == "DEFAULT" ? "起拍价" : "售价" }}
              </p>
              <div class="pric">￥{{ options.afrom.startPrice }}</div>
            </div>
            <div class="size2_3" v-if="options.afrom.type == 'DEFAULT'" @click="options.isFinishBidPriceShow = true">
              <p class="tit">加价幅度</p>
              <div class="pric">￥{{ options.afrom.bidAmount }}</div>
            </div>
            <div class="size2_3">
              <div class="tit">是否付邮费:</div>
              <div class="pric" style="padding-left: 1rem; margin-top: .6rem;">
                <van-switch :model-value="options.isMail" size="1.1rem" @update:model-value="onUpdateMail" />
                <div v-if="options.isMail" @click="options.isMailShow = true"
                  style="float: right; border: 1px solid #333; width: 6rem;line-height: 1rem;">
                  <span v-if="options.afrom.mailPrice == ''">请输入邮费金额</span>
                  <span v-else>￥{{ options.afrom.mailPrice }}</span>
                </div>
              </div>
            </div>
            <div class="size2_3" v-if="options.afrom.type == 'DEFAULT'">
              <div class="tit">设置保证金:</div>
              <div class="pric" style="padding-left: 1rem; margin-top: .6rem;">
                <van-switch :model-value="options.isBond" size="1.1rem" @update:model-value="onUpdateBond" />
                <div v-if="options.isBond && options.afrom.type == 'DEFAULT'" @click="options.isBondShow = true"
                  style="float: right; border: 1px solid #333; width: 6rem;line-height: 1rem;">
                  <span v-if="options.afrom.bondPrice == ''">请输入保证金金额</span>
                  <span v-else>￥{{ options.afrom.bondPrice }}</span>
                </div>
              </div>
            </div>
            <div class="size2_1" v-if="options.afrom.type == 'DEFAULT'">
              <p class="tit">延时设置</p>
              <a :class="options.afrom.delayedTime == 0 ? 'on' : ''" href="javascript:;">不设置</a>
              <a :class="options.afrom.delayedTime == 30 ? 'on' : ''" @click="options.afrom.delayedTime = 30"
                href="javascript:;">30秒</a>
              <a :class="options.afrom.delayedTime == 60 ? 'on' : ''" @click="options.afrom.delayedTime = 60"
                href="javascript:;">1分钟</a>
              <a :class="options.afrom.delayedTime == 180 ? 'on' : ''" @click="options.afrom.delayedTime = 180"
                href="javascript:;">3分钟</a>
              <a :class="options.afrom.delayedTime == 300 ? 'on' : ''" @click="options.afrom.delayedTime = 300"
                href="javascript:;">5分钟</a>
              <a :class="options.afrom.delayedTime == 600 ? 'on' : ''" @click="options.afrom.delayedTime = 600"
                href="javascript:;">10分钟</a>
            </div>
            <div class="size2_1 redpacketChange">
              <p class="tit">红包设置</p>
              <a :class="options.afrom.amountRatio == 0 ? 'on' : ''" @click="setRedPacket(false, 0)"
                href="javascript:;">不发红包</a>
              <a :class="options.afrom.amountRatio == 1 ? 'on' : ''" @click="setRedPacket(true, 1)"
                href="javascript:;">1%</a>
              <a :class="options.afrom.amountRatio == 2 ? 'on' : ''" @click="setRedPacket(true, 2)"
                href="javascript:;">2%</a>
              <a :class="options.afrom.amountRatio == 3 ? 'on' : ''" @click="setRedPacket(true, 3)"
                href="javascript:;">3%</a>
              <a :class="options.afrom.amountRatio == 4 ? 'on' : ''" @click="setRedPacket(true, 4)"
                href="javascript:;">4%</a>
              <a :class="options.afrom.amountRatio == 5 ? 'on' : ''" @click="setRedPacket(true, 5)"
                href="javascript:;">5%</a>
              <a :class="options.afrom.amountRatio == 6 ? 'on' : ''" @click="setRedPacket(true, 6)"
                href="javascript:;">6%</a>
              <a :class="options.afrom.amountRatio == 7 ? 'on' : ''" @click="setRedPacket(true, 7)"
                href="javascript:;">7%</a>
              <a :class="options.afrom.amountRatio == 8 ? 'on' : ''" @click="setRedPacket(true, 8)"
                href="javascript:;">8%</a>
              <a :class="options.afrom.amountRatio == 9 ? 'on' : ''" @click="setRedPacket(true, 9)"
                href="javascript:;">9%</a>
              <a :class="options.afrom.amountRatio == 10 ? 'on' : ''" @click="setRedPacket(true, 10)"
                href="javascript:;">10%</a>
            </div>
            <div class="clear"></div>
          </div>
          <div class="size3">
            <a href="javascript:;" @click="update">修改</a>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
  <van-popup v-model:show="options.isFinishStartPriceShow" position="bottom">
    <van-number-keyboard v-if="options.isFinishStartPriceShow" v-model="options.afrom.startPrice" theme="custom"
      extra-key="." :hide-on-click-outside="true" close-button-text="完成" :show="options.isFinishStartPriceShow"
      @blur="options.isFinishStartPriceShow = false" />
  </van-popup>
  <van-popup v-model:show="options.isFinishBidPriceShow" position="bottom">
    <van-number-keyboard v-if="options.isFinishBidPriceShow" v-model="options.afrom.bidAmount" theme="custom"
      extra-key="." :hide-on-click-outside="true" close-button-text="完成" :show="options.isFinishBidPriceShow"
      @blur="options.isFinishBidPriceShow = false" />
  </van-popup>
  <van-dialog v-model:show="options.isOnlineShow" title="发布拍卖" show-cancel-button @confirm="online">
    <div class="xzsize">
      <div class="xzsize0">
        <div class="f_mask0"></div>
        <div class="size2">
          <div class="size2_4" @click="options.isStartTimeShow = true">
            <p class="tit">开始时间</p>
            <div class="pric">
              {{
                stringUtils.dateFormat(
                  options.publish.startTime,
                  "YYYY-MM-DD HH:mm"
                )
              }}
            </div>
          </div>
          <div class="size2_4" @click="options.isEndTimeShow = true">
            <p class="tit">结束时间</p>
            <div class="pric">
              {{
                stringUtils.dateFormat(
                  options.publish.endTime,
                  "YYYY-MM-DD HH:mm"
                )
              }}
            </div>
          </div>
          <!-- <div class="size3">
            <a href="javascript:void()">确定</a>
          </div> -->
        </div>
      </div>
    </div>
  </van-dialog>
  <van-popup v-model:show="options.isStartTimeShow" position="bottom">
    <van-datetime-picker type="datetime" :minDate="options.minDate" @confirm="onStartConfirm"
      @cancel="options.isStartTimeShow = false" />
  </van-popup>
  <van-popup v-model:show="options.isEndTimeShow" position="bottom">
    <van-datetime-picker type="datetime" :minDate="options.maxDate" @confirm="onEndConfirm"
      @cancel="options.isEndTimeShow = false" />
  </van-popup>
  <van-popup v-model:show="options.isBondShow" :overlay="false" position="bottom">
    <van-number-keyboard v-if="options.isBondShow" v-model="options.afrom.bondPrice" theme="custom" extra-key="."
      :hide-on-click-outside="true" close-button-text="完成" :show="options.isBondShow"
      @blur="options.isBondShow = false" />
  </van-popup>
  <van-popup v-model:show="options.isMailShow" :overlay="false" position="bottom">
    <van-number-keyboard v-if="options.isMailShow" v-model="options.afrom.mailPrice" theme="custom" extra-key="."
      :hide-on-click-outside="true" close-button-text="完成" :show="options.isMailShow"
      @blur="options.isMailShow = false" />
  </van-popup>
  <to-top></to-top>
</template>

<script>
import { reactive } from "vue";
import auctionApi from "@/api/auctionApi";
import VabCountDown from "@/components/VabCountDown.vue";
import ToTop from "@/components/ToTop.vue";
import { Toast, Dialog } from "vant";
import moment from "moment";
export default {
  components: { VabCountDown, ToTop },
  setup() {
    let curTime = new Date();
    curTime = new Date(curTime.setHours(curTime.getHours() + 1));
    const options = reactive({
      auctions: [],
      auction: {
        id: "",
        title: "",
        pictureSmall: "",
        goods: [
          {
            id: "",
            startPrice: "",
            bidAmount: "",
            mailPrice: ''
          },
        ],
        synopsis: "",
        delayedRule: null,
        redPacket: null,
        bondRule: null,
        isMail: 0,
        isBond: 0,
        type: 'DEFAULT'
      },
      minDate: curTime,
      maxDate: new Date(curTime.setHours(curTime.getHours() + 1)),
      afrom: {
        id: "",
        title: "",
        pictureSmall: "",
        goodsId: "",
        delayedTime: 0,
        startPrice: "",
        bidAmount: "",
        synopsis: "",
        isRedPacket: false,
        amountRatio: 0,
        mailPrice: "",
        bondPrice: "",
        isMail: 0,
        isBond: 0,
        type: 'DEFAULT'
      },
      publish: {
        id: "",
        startTime: "",
        endTime: "",
      },
      totalCount: {
        online: 0,
        offline: 0,
        passin: 0,
        end: 0,
        all: 0,
      },
      step: "default",
      refreshing: false,
      loading: false,
      finished: false,
      total: 0,
      params: {
        draw: 1,
        pageNo: 0,
        length: 10,
      },
      pageCount: 1,
      pageNo: 1,
      isModifyAuctionShow: false,
      isFinishBidPriceShow: false,
      isFinishStartPriceShow: false,
      isStartTimeShow: false,
      isEndTimeShow: false,
      isOnlineShow: false,
      isMail: false,
      isMailShow: false,
      isBond: false,
      isBondShow: false,
    });
    const onUpdateMail = (val) => {
      options.isMail = val;
      options.afrom.isMail = options.isMail ? 1 : 0;
    }
    const onUpdateBond = (val) => {
      options.isBond = val;
      options.afrom.isBond = options.isBond ? 1 : 0;
    }
    function onRefresh() {
      options.finished = false;
      options.loading = true;
      options.refreshing = true;
      onLoad();
    }
    async function onLoad() {
      console.log("refreshing:" + options.refreshing);
      if (options.refreshing) {
        options.auctions = [];
        options.pageNo = 1;
        options.pageCount = 1;
        options.refreshing = false;
      } else {
        if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
      }
      options.params.pageNo = (options.pageNo - 1);
      if (
        options.auctions.length > 0 &&
        options.total > 0 &&
        options.auctions.length >= options.total
      ) {
        options.finished = true;
        options.loading = false;
        return;
      }
      await auctionApi.searchMy(options.params).then((res) => {
        if (res.status == 200) {
          options.total = res.recordsTotal;
          let count = Math.ceil(res.recordsTotal / options.params.length);
          options.pageCount = count;
          console.log("pageCount:" + options.pageCount);
          if (res.data.length > 0) {
            res.data.forEach((item) => {
              if (options.auctions.length < res.recordsTotal) {
                options.auctions.push(item);
              }
            });
          } else {
            options.finished = true;
          }
        }
        options.loading = false;
        if (options.auctions.length >= options.total) {
          options.finished = true;
        }
      });
    }
    const openOnline = (item) => {

      options.publish = {
        id: item.id,
        startTime: item.startTime,
        endTime: item.endTime,
      };
      if (item.startTime == null) {
        let curTime = new Date();
        curTime = new Date(curTime.setHours(curTime.getHours()+1));
        let enTime = new Date();
        enTime = new Date(enTime.setHours(enTime.getHours() + 2));
        options.publish.startTime = curTime;
        options.publish.endTime = enTime;
      }
      options.isOnlineShow = true;
    };
    const openModify = async (id) => {
      await auctionApi.sGet({ id: id }).then((res) => {
        if (res.status == 200) {
          options.auction = res.result;
          options.afrom = {
            id: options.auction.id,
            title: options.auction.title,
            pictureSmall: options.auction.pictureSmall,
            goodsId: options.auction.goods[0].id,
            delayedTime:
              options.auction.delayedRule != null
                ? options.auction.delayedRule.delayTime
                : 0,
            startPrice: options.auction.goods[0].startPrice.toString(),
            bidAmount: options.auction.goods[0].bidAmount == null ? '' : options.auction.goods[0].bidAmount.toString(),
            synopsis: options.auction.synopsis,
            isRedPacket:
              options.auction.redPacket != null
                ? options.auction.redPacket.isAvailable == 1
                : false,
            amountRatio:
              options.auction.redPacket != null
                ? options.auction.redPacket.amountRatio
                : 0,
            type: options.auction.type,
            mailPrice: options.auction.goods[0].mailPrice != null ? options.auction.goods[0].mailPrice.toString() : '',
            bondPrice: options.auction.bondRule != null ? options.auction.bondRule.amount.toString() : '',
            isBond: options.auction.bondRule != null
              ? options.auction.bondRule.isAvailable
              : 0,
          };
          if (options.auction.isMail == 1) {
            options.isMail = true;
          }
          if (options.auction.isBond == 1) {
            options.isBond = true;
          }
          options.isModifyAuctionShow = true;
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const copy = (item) => {
      Dialog.confirm({
        title: "复制",
        message: "亲，您确认要复制拍卖【" + item.title + "】？",
      }).then(() => {
        auctionApi.copy({ id: item.id }).then((res) => {
          if (res.status == 200) {
            onRefresh();
            getTotalCount();
          } else {
            Toast.fail(res.message);
          }
        });
      });
    }
    const update = () => {
      if(options.afrom.type=='FIXED'){
        options.afrom.delayedTime=0;
        options.afrom.bidAmount=0;
      }
      if (
      (options.afrom.bidAmount == "" ||
      parseFloat(options.afrom.bidAmount) == 0) &&
      options.afrom.type == "DEFAULT"
    ) {
      Toast.fail("加价金额不能为空或零！");
      return;
    }
    if (
      (options.afrom.startPrice == "" ||
      parseFloat(options.afrom.startPrice) == 0) &&
      options.afrom.type == "FIXED"
    ) {
      Toast.fail("一口价拍卖价格不能为空或零！");
      return;
    }
      auctionApi.update(options.afrom).then((res) => {
        if (res.status == "200") {
          onRefresh();
          options.isModifyAuctionShow = false;
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const setRedPacket = (isOk, value) => {
      options.afrom.amountRatio = value;
      options.afrom.isRedPacket = isOk;
    };
    const onStartConfirm = (value) => {
      options.publish.startTime = value;
      options.isStartTimeShow = false;
    };
    const onEndConfirm = (value) => {
      options.publish.endTime = value;
      options.isEndTimeShow = false;
    };
    const online = () => {
      options.publish.startTime = moment(options.publish.startTime);
      options.publish.endTime = moment(options.publish.endTime);
      auctionApi.online(options.publish).then((res) => {
        if (res.status == 200) {
          onRefresh();
          getTotalCount();
          options.isOnlineShow = false;
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const offline = (item) => {
      Dialog.confirm({
        title: "下线",
        message: "亲，您确认要下线拍卖【" + item.title + "】？",
      }).then(() => {
        auctionApi.offline({ id: item.id }).then((res) => {
          if (res.status == 200) {
            onRefresh();
            getTotalCount();
          } else {
            Toast.fail(res.message);
          }
        });
      });
    };
    const getTotalCount = () => {
      auctionApi.getAuctionCount().then((res) => {
        if (res.status == 200) {
          options.totalCount = res.result;
        }
      });
    };
    const defaultSearch = () => {
      options.step = "default";
      options.params = {
        draw: 1,
        start: 0,
        length: 10,
      };
      onRefresh();
    };
    const onlineSearch = (status) => {
      options.step = status == 1 ? "online" : "offline";
      options.params = {
        draw: 1,
        start: 0,
        length: 10,
        online: status,
        status: 2,
      };
      onRefresh();
    };
    const endSearch = () => {
      options.step = "end";
      options.params = {
        draw: 1,
        start: 0,
        length: 10,
        status: 6,
      };
      onRefresh();
    };
    const copySearch = () => {
      options.step = "copy";
      options.params = {
        draw: 1,
        start: 0,
        length: 10,
        status: 6,
        isCopy: 1
      };
      onRefresh();
    };
    getTotalCount();
    return {
      options,
      onRefresh,
      onLoad,
      openModify,
      setRedPacket,
      update,
      openOnline,
      onStartConfirm,
      onEndConfirm,
      online,
      offline,
      defaultSearch,
      onlineSearch,
      endSearch,
      onUpdateMail,
      onUpdateBond,
      copySearch,
      copy,
    };
  },
};
</script>

<style>
.dpbtn2 a {
  float: right;
  margin-left: 0.1rem;
}

.redpacketChange a {
  float: left;
  line-height: 1rem !important;
}

.dpbtn2 .a2 {
  background-color: darkorange;
  border: darkorange;
}
</style>